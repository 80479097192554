import React, { useContext } from 'react';

import BlogProp from './itemProp/BlogProp';

import { PiNewspaperDuotone } from "react-icons/pi";
import LanguageContext from '../../LanguageContext';
import {Link} from "react-router-dom";

const BlogGridView = () => {
    const { translations } = useContext(LanguageContext);

    return (
        <>
        
    
                <div className="main-content" style={{marginTop:"15px"}}>
                    
              
                    
                    <div className="rn-blog-area ">
              
                    
                        <div className='rn-blog-areainner' >
                        
                            <div className="row mt_dec--30">
                            <div style={{width:"100%;"}}><h1  style={{fontWeight:"300",fontSize:"65px",marginBottom:"0px", fontFamily:"var(--font-tt-lakes-neue-extended-demibold)", color:"white"}}>{translations.lowerside}</h1></div>
    
                            <div style={{width:"100%",marginBottom:"20px"}}><span  style={{fontWeight:"300",fontSize:"18px",marginBottom:"20px",fontFamily:"var(--font-tt-lakes-neue-trial-thin)",color:"white"}}>{translations.lowersidesub}</span> <Link to="/news">
                            <PiNewspaperDuotone style={{fontSize:"32px",color:"var(--color-primary)",marginLeft:"5px"}}/></Link></div>
                                <BlogProp column="col-lg-3 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
                            </div>
                        </div>
                    </div>
                </div>

        </>
    )
}
export default BlogGridView;