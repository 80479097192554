import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Card, CardMedia, Modal, CircularProgress, OutlinedInput, DialogContentText, InputAdornment, IconButton, DialogActions, Dialog, DialogTitle, Button, DialogContent, Typography, Box, TextField, InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import { green } from "@mui/material/colors";
import CheckIcon from '@mui/icons-material/Check';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

// Admin panel React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDAvatar from "components/MDAvatar";

// Admin panel React context
import { useMaterialUIController } from "context";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


//modal Styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

//Modal Style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '1rem',
  boxShadow: 24,
};
function Bill({ id, title, body, date, logo}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [brandsModal, setBrandsModal] = React.useState(false);
  const [bankLogoModal, setBankLogoModal] = React.useState(false);
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [brandsNotification, setBrandsNotification] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [imageProgress, setImageProgress] = React.useState(0);
  const [imageProgressValue, setImageProgressValue] = React.useState(0);
  const [categoriesDropdown, setCategoriesDropdown] = React.useState([])
  const [saleId, setSaleId] = React.useState('')
  const [discountsId, setDiscountsId] = React.useState('')
  const [dbBrandsData, setDbBrandsData] = React.useState({})
  const [carouselData, setCarouselData] = React.useState(title)
  const [bombay, setBombay] = useState(''); // Başlangıç değeri olarak props'tan gelen değeri kullan veya boş bir dize
  const [editorData, setEditorData] = useState('<p>Buraya metin ekleyin...</p>');
  const [file, setFile] = useState(null);
  const [imagenamee, simagenamee] = React.useState('');
  
  const [lonenlyimagename, Setlonenlyimagename] = React.useState('');
  function pathToFile(filePath) {
    // Dosyanın adını çıkartın
    const fileName = filePath.split('/').pop();
  
    // Dosya uzantısını tespit edin (örneğin, png)
    const fileExtension = fileName.split('.').pop();
  
    // Dosyanın URL'sini oluşturun
    const fileURL = process.env.PUBLIC_URL + filePath;
  
    // Fetch ile dosya içeriğini alın
    return fetch(fileURL)
      .then(response => response.blob())
      .then(blob => new File([blob], fileName, { type: `image/${fileExtension}` }))
      .catch(error => {
        console.error("Dosya oluşturulurken hata oluştu:", error);
        return null;
      });
  }
  
  // Kullanım örneği

  

  const handleBombay= (e) => {
    setBombay(e.target.value);
  }
  useEffect(() => {
    if (title) {
      setBombay(title);
    }
    if(body){
      const bodyString = body.join('\n'); // Dizi öğelerini '\n' karakteri ile birleştirerek bir string oluşturur

      setEditorData(bodyString);
    }
    if(logo){
      const cleanLogoName = logo.substring(1);
      simagenamee(cleanLogoName);
      pathToFile(logo)
      .then(filer => {
        if (filer) {
          console.log("Başarıyla oluşturulan dosya:", filer);

          setFile(filer);
          // Oluşturulan dosyayı kullanın
        } else {
          alert("Dosya oluşturulurken hata oluştu.");

        }
      });
   
      
      //setFile(imageFile);

    }
  }, [title,body,logo]);


  const navigate = useNavigate()
  const tamamlandi = () => {
    // İşlem tamamlandığında '/admin/brands' sayfasına yönlendirme yapılacak
    navigate('/admin/haberler');
  };
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };  //file upload

  const editorConfig = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'link', 'bulletedList', 'numberedList', 'horizontalLine', '|',
      'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify', '|',
      'fontSize', 'fontColor', 'fontBackgroundColor', '|',
      'indent', 'outdent', '|',
, 'blockQuote',  '|',
      'undo', 'redo'
    ],    ckfinder: {
      uploadUrl: 'http://your-upload-url-here', // Resim yükleme URL'si
    },  
  };


  
  const maxFileSizeInBytes = 5 * 1024 * 1024; // 5MB

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  
    if (selectedFile) {
      if (selectedFile.size <= maxFileSizeInBytes) {
        const image = new Image();
        image.src = URL.createObjectURL(selectedFile);
        image.onload = () => {
          if (image.width >= 390 && image.height >= 240) {
            setFile(selectedFile);
            const directoryPath = process.env.PUBLIC_URL+"images/blog/";
            const filename = selectedFile.name;
            const newFileName = filename.replace(/ğ/g, 'g').replace(/ü/g, 'u').replace(/ş/g, 's').replace(/ı/g, 'i').replace(/ö/g, 'o').replace(/ç/g, 'c').replace(/Ğ/g, 'G').replace(/Ü/g, 'U').replace(/Ş/g, 'S').replace(/I/g, 'I').replace(/Ö/g, 'O').replace(/Ç/g, 'C');
            const fullPath = directoryPath + newFileName;
    
            simagenamee(fullPath);
            Setlonenlyimagename(newFileName);
    
            // Diğer işlemleri burada yapabilirsiniz.
          } else {
            alert("Resim çok küçük! En az 390x240 piksel olmalı.");
          }
        }
      
      } else {
        alert("Resim çok büyük! Maksimum 5MB olmalı.");
      }
    }
  };



  

  
  function stripHtml(html) {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
  const htmlContent = body;
  const textContent = stripHtml(htmlContent);
  const limitedText = textContent.slice(0, 200);
  const handleDelete = (degerdata) => {
  
    const formData = new FormData();

    formData.append('id', degerdata);
    formData.append('name', logo);
    formData.append('blog', 'blog'); 

      fetch('https://mrtportservices.com:5000/deleteData?origin=startlog', {
        method: 'POST',
        headers: {
        },
        body: formData,
        origin:'startlog'
      })
        .then((addDataResponse) => {
          if (addDataResponse.status === 200) {
            alert('Veri başarıyla silindi');
            // Başka bir işlem yapabilirsiniz veya kullanıcıyı yönlendirebilirsiniz.
            brandsModalClose()
            tamamlandi()
            window.location.reload();

          } else {
            alert('Veri silinemedi');
          }
        })
        .catch((error) => {
          console.error('Bir hata oluştu: ' + error);
          

        });
  
    }
  const deleteById =  (dataId) => {
handleDelete(dataId);
  };

  

  const handleEdit = (degerdata) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('blog', 'blog'); 
      formData.append('dataname', lonenlyimagename);
      formData.append('degerdata', JSON.stringify(degerdata)); // degerdata JSON string olarak ekleniyor
  
      fetch('https://mrtportservices.com:5000/uploadAndEditData?origin=startlog', {
        method: 'POST',
        body: formData,
      })
      .then((response) => {
        if (response.status === 200) {
          alert('Veri başarıyla güncellendi');
          brandsModalClose();
          brandsNotificationOpen();
          setImageProgress(0);
          setImageProgressValue(0);
          window.location.reload();
        } else {
          alert('Veri güncellenemedi');
        }
      })
      .catch((error) => {
        alert('Bir hata oluştu: ' + error);
      });
    }
  };
  
  const onUpdateBrand =  () => {
    //update data into firestore
    const updateData = {
      id: dbBrandsData.id,
      title: dbBrandsData.title,
      body: dbBrandsData.body,
      date: dbBrandsData.date,
      logo: dbBrandsData.logo
    }
    if (!bombay.trim()) {
      alert('Başlık alanı boş bırakılamaz');
      return; // Başlık alanı boşsa işlemi durdur
    }
    if (editorData && editorData !== '<p>Buraya metin ekleyin...</p>') {
      // editorData dolu ve özel bir değere sahip, burada istediğiniz işlemi yapabilirsiniz
    } else {
      alert('Lütfen metin ekleyin'); // Eğer boş veya varsayılan metne eşitse uyarı göster
      return;
    }
    if (!file) {
      alert('Lütfen bir dosya seçin'); // Eğer file boşsa, uyarı göster
      return;
    }
    const bugun = new Date();
    const gun = bugun.getDate();
    const turkceAylar = [
      'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
      'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ];
    const ay = turkceAylar[bugun.getMonth()];
    const yil = bugun.getFullYear();
    const birlesik=gun+" "+ay+" "+yil;
    //const blogData = jsor.blog;
    //const dataCount = blogData.length+1;
    const yeniVeri = {
      id: id,
      title: bombay, 
      image:  imagenamee, // brandsData'nın image'ını kullanabilirsiniz
      largeImage: imagenamee, // brandsData'nın largeImage'ını kullanabilirsiniz
      date: birlesik,
      body: [editorData], 
   
    };
    handleEdit(yeniVeri);

  }

  const deleteAlertOpen = () => setDeleteAlert(true);
  const deleteAlertClose = () => setDeleteAlert(false);
  const brandsModalOpen = () => setBrandsModal(true);
  const brandsModalClose = () => {
    setBrandsModal(false)
    setLoading(false)
    setError('')
    setImageProgress(0)
    setImageProgressValue(0)
    const bodyString = body ? body.join('\n') : '';
    setEditorData(bodyString);
    setBombay(title);

  };
  const brandsNotificationOpen = () => setBrandsNotification(true);
  const brandsNotificationClose = () => setBrandsNotification(false);
  const bankLogoModalOpen = () => setBankLogoModal(true);
  const bankLogoModalClose = () => setBankLogoModal(false);

  return (
    <>
      <MDSnackbar
        color="success"
        icon="check"
        title="Successfully Update"
        // content="Hello, world! This is a brandsNotification message"
        // dateTime="11 mins ago"
        open={brandsNotification}
        onClose={brandsNotificationClose}
        close={brandsNotificationClose}
      />
      <Dialog
        open={deleteAlert}
        onClose={deleteAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Silmek istediğinize emin misiniz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteAlertClose}>Cancel</Button>
          <Button sx={{ color: 'error.main' }} onClick={() => { deleteById(id) }}>
            Sil
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={bankLogoModal}
        onClose={bankLogoModalClose}
        aria-labelledby="bankLogoModal-bankLogoModal-title"
        aria-describedby="bankLogoModal-bankLogoModal-description"
      >
        <Box sx={style}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "transparent",
              boxShadow: "none",
              overflow: "visible",
            }}
          >
            <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
              <CardMedia
                src={logo}
                component="img"
                title="Brand Logo"
                sx={{
                  maxWidth: "100%",
                  margin: 0,
                  boxShadow: ({ boxShadows: { md } }) => md,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </MDBox>
          </Card>
        </Box>
      </Modal>

      <BootstrapDialog
        onClose={brandsModalClose}
        aria-labelledby="customized-dialog-title"
        open={brandsModal}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={brandsModalClose}>
          <Typography variant="h3" color="secondary.main" sx={{ pt: 1, textAlign: "center" }}>Edit</Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 2, maxWidth: "100%", display: "flex", direction: "column", justifyContent: "center" },
          }}
          noValidate
          autoComplete="off"
        >
        <div>
        <OutlinedInput
        placeholder="Başlığı buraya girin."
        value={bombay}
          sx={{
            height: "2.8rem",
            padding: "8px",
            width: "100%",
            marginBottom: "10px", // Altındaki textarea ile aralarındaki boşluğu belirlemek için margin ekledik
          }}
          onChange={handleBombay}
          
          id="outlined-adornment-amount"
        ></OutlinedInput>
        <div>
        <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={handleEditorChange}
        style={{ width: '100%', height: '500px' }}
        config={editorConfig}
      />
  </div>
      </div>
      
          <Box sx={{ maxWidth: "100%", m: 2 }}>
            <FormControl fullWidth sx={{ mt: 2 }} >
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Mevcut Resim" style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover', marginRight: '10px' }} />
            <InputAdornment position="start" sx={{ marginTop: 2 }}>
              <input type="file" accept="image/*" onChange={handleFileChange} />
            </InputAdornment>
          </div>
           
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress
                      variant="determinate"
                      size={25}
                      sx={{
                        color: green[500],
                      }}
                      value={imageProgress} />
                      <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {imageProgressValue === 100 ? <CheckIcon /> : null}
                    </Box>
                  </Box>

            </FormControl>
          </Box>
          {error === '' ? null :
            <MDBox mb={2} p={1}>
              <TextField
                error
                id="standard-error"
                label="Error"
                InputProps={{
                  readOnly: true,
                  sx: {
                    "& input": {
                      color: "red",
                    }
                  }
                }}
                // defaultValue="Invalid Data!"
                value={error}
                variant="standard"
              />
            </MDBox>}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        {loading ?
          <CircularProgress
            size={30}
            sx={{
              color: green[500],
            }}
          /> : <MDButton variant="contained" color="info" type="submit"
            // disabled={brandsData.name === '' || brandsData.contactNo === '' || brandsData.website === '' || brandsData.category === '' || brandsData.logo === '' ? true : false}
            onClick={onUpdateBrand}
          >Kaydet</MDButton>
        }
      </DialogActions>
      </BootstrapDialog>

      <MDBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mt={2}
      >
        <MDBox width="100%" display="flex" flexDirection="column">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={2}
          >
          <MDTypography variant="caption" color="text">
          ID:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {id}
          </MDTypography>
        </MDTypography>
            <MDTypography variant="caption" color="text">
              Başlık:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                {title}
              </MDTypography>
            </MDTypography>

            <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
              <MDBox mr={1}>
                <MDButton variant="text" color="error" onClick={deleteAlertOpen}>
                  <Icon>delete</Icon>&nbsp;Sil
                </MDButton>
              </MDBox>
              <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={brandsModalOpen}>
                <Icon>edit</Icon>&nbsp;Değiştir
              </MDButton>
            </MDBox>

          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              İçerik:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {limitedText}
              </MDTypography>
            </MDTypography>
          </MDBox>
           <MDBox mb={0} lineHeight={0} display="flex" flexDirection="row" alignItems="center">
            <MDTypography variant="caption" color="text">
              Resim:&nbsp;&nbsp;&nbsp;
            </MDTypography>
            <MDAvatar sx={{ cursor: "pointer" }} onClick={bankLogoModalOpen} src={logo} size="xxl" />
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

// Setting default values for the props of Bill

// Typechecking props for the Bill
Bill.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default Bill;
