import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LanguageContext from "../../LanguageContext.js";
import LanguageDropdown from "./LanguageSelect.js";
import { useNavigate } from 'react-router-dom';

const Nav = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const { translations } = useContext(LanguageContext);
  const handleClick = () => {
    window.location.href = '/getoffer';
  };
  const scrollDown = () => {

        navigate('/getoffer'); // '/getoffer' sayfasına yönlendir

  
  };
  const navigate = useNavigate();

  return (
    <ul className="mainmenu">
      <li className="has-droupdown ">
        <ul style={{ margin: 0 }} className="navwreck">
          <Link to="/">{translations.navone}</Link>
        </ul>
      </li>
      <li className="has-droupdown ">
        <Link to="#">
          {translations.navtwo}
          <ul className="submenu">
            <li>
              <Link to="/air-freight">{translations.navtwoone}</Link>
            </li>
            <li>
              <Link to="/sea-freight">{translations.navtwotwo}</Link>
            </li>
            <li>
              <Link to="/road-freight">{translations.navtwothree}</Link>
            </li>
            <li>
              <Link to="/pharma">{translations.pharmatitle}</Link>
            </li>
            <li>
              <Link to="/warehouse">{translations.navtwofour}</Link>
            </li>
            <li>
              <Link to="/etgb">{translations.navtwofive}</Link>
            </li>
            <li>
              <Link to="/counseling">{translations.navtwosix}</Link>
            </li>
          </ul>
        </Link>
      </li>
      <li className="has-droupdown ">
        <Link to="#">
          {translations.navseven}
          <ul className="submenu">
            <li>
              <Link to="/container">{translations.navsevenone}</Link>
            </li>
    
            <li>
              <Link to="/codes">{translations.navseventwo}</Link>
            </li>
            <li>
              <Link to="/dangerous">{translations.navseventhree}</Link>
            </li>
          </ul>
        </Link>
      </li>
      <li className="has-droupdown ">
        <Link to="#">
          {translations.navthree}
          <ul className="submenu">
            <li>
              <Link to="/news">{translations.navthreeone}</Link>
            </li>
            <li>
              <Link to="/gallery">{translations.navthreetwo}</Link>
            </li>
          </ul>
        </Link>
      </li>
      <li className="has-droupdown ">
        <ul className="navwreck">
          <Link to="/tarihce">{translations.navfour}</Link>
        </ul>
      </li>
      <li className="has-droupdown ">
        <ul className="navwreck">
          <Link to="/iletisim">{translations.navfive}</Link>
        </ul>
      </li>
      <li className="has-droupdown ">
        <div className="btn-cont" style={{ marginTop: "-5px" }}>
          <LanguageDropdown
            language={language}
            changeLanguage={changeLanguage}
          />
        </div>
      </li>
      <li></li>
      <div className=" raprap damboyinno" >                    
  <div className="rxxxsda" onClick={scrollDown}><span style={{ lineHeight: "20px", wordWrap: 'break-word',width:"90%"}}>{translations.navsix}</span></div>
  <div className="rxxxsda" >  <span style={{ lineHeight: "20px", wordWrap: 'break-word',width:"90%"}}>{translations.navsixt}</span></div>

</div>
    </ul>
  );
};
export default Nav;
