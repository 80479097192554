import { createContext, useReducer, useEffect } from "react";

const getInitialState = () => {
  let currentUser = null;
  let role = null;

  try {
    const user = localStorage.getItem("user");
    const roleData = localStorage.getItem("role");

    if (user) currentUser = JSON.parse(user);
    if (roleData) role = JSON.parse(roleData);
  } catch (error) {
    console.error("Failed to parse localStorage data", error);
  }

  return {
    currentUser,
    role,
  };
};

const initialState = getInitialState();

// Reducer
function AuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case "LOGOUT": {
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      return {
        currentUser: null,
        role: null,
      };
    }
    case "LOGIN_ROLE": {
      return {
        ...state,
        role: action.payload,
      };
    }
    case "LOGOUT_ROLE": {
      return {
        ...state,
        role: null,
      };
    }
    default: {
      return state;
    }
  }
}

export const AuthContext = createContext(initialState);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    try {
      if (state.currentUser) {
        localStorage.setItem("user", JSON.stringify(state.currentUser));
      } else {
        localStorage.removeItem("user");
      }

      if (state.role) {
        localStorage.setItem("role", JSON.stringify(state.role));
      } else {
        localStorage.removeItem("role");
      }
    } catch (error) {
      console.error("Failed to set localStorage data", error);
    }
  }, [state.currentUser, state.role]);

  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, role: state.role, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
