import React, { useState, useEffect } from "react";
import BlogList from './BlogList';


const BlogProp = ({ column, StyleVarProp }) => {
    const [getAllItems, setGetAllItems] = useState([]);
    const [dataVisibleCount, setDataVisibleCount] = useState(9);
    const [dataIncrement] = useState(3);
    const [visibleItems, setVisibleItems] = useState([]);
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    
        fetchData(); // Veriyi ilk yükleme
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
    
      const fetchData = async () => {

        try {
          const response = await fetch("https://mrtportservices.com:5000/getData?blog=blog&origin=startlog"); // API endpointinizi buraya ekleyin
          const result = await response.json();
          setGetAllItems(result.slice()); // Veriyi çekip ters çevirme
          setVisibleItems(result.slice(0, dataVisibleCount));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            document.documentElement.offsetHeight
        ) {
            // Sayfanın sonuna gelindiğinde yeni veri yükle
            handleLoadmorebl();
        }
    };

    const handleLoadmorebl = () => {
        let tempCount = dataVisibleCount + dataIncrement;
        if (dataVisibleCount >= getAllItems.length) {
            // Tüm veriler görüntülendi, yeni veri eklenmiyor
            return;
        } else {
            setDataVisibleCount(tempCount);
            setVisibleItems(getAllItems.slice(0, tempCount));
        }
    };

    return (
        <>
            <div className="col-lg-12">
                <div className="row row--15">
                    {visibleItems.map((item) => (
                        <div key={item.id} className={column}>
                            <BlogList StyleVar={StyleVarProp} data={item} />
                        </div>
                    ))}
                </div>
            </div>
     
        </>
    )
}

export default BlogProp;
