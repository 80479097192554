import React, { useEffect, useRef,useContext} from 'react';
import World from '../ts/world/Word';
import LanguageContext from '../LanguageContext';
import { Link } from "react-router-dom";
function LoadingComponent() {
  const { translations } = useContext(LanguageContext);

    const worldRef = useRef(null);
    const html2canvasRef = useRef(null);
    const loading = useRef(null);
    useEffect(() => {
      if (worldRef.current) {
        const worldInstance = new World({
          dom: worldRef.current,
        });
  
        return () => {
          if (worldInstance) {
          }
        };
      }
    }, []);
  return (
    <>
    <div  ref={loading} id="loading">
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
      <div >YÜKLENİYOR...</div>
    </div>
    <div  ref={html2canvasRef} id="html2canvas" className="css3d-wapper">
      <div className="fire-div"></div>
    </div>
    <div className='brraa'>
    <div ref={worldRef} id="earth-canvas">
    <div className='sxsdddd' style={{display:"flex",flexDirection:"row"}}>
    <span className='outtext'>{translations.maintitle1}</span>
    <span className='outtext2'>{translations.maintitle1}</span>
    <Link to="/iletisim">
    <div className='buttonnewhomefinance'>
    {translations.contacttitle}
      <span className="first"></span>
      <span className="second"></span>
      <span className="third"></span>
      <span className="fourth"></span>
      
    </div>
    </Link>
      </div>
    
  
    </div>
    </div>

  </>
  )
}

export default LoadingComponent;