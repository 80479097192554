export default [{
  startArray: {
    name: 'Turkey',
    N: 41.015137,
    E: 28.979530,
  },
  endArray: [{
      name: 'Tayland',
      N: 22, //维度
      E: 100.49074172973633, //经度
    },
    {
      name: 'Australia',
      N: -23.68477416688374,
      E: 133.857421875,
    },

    {
      name: 'Russia',
      N: 55.58182,
      E: 37.385195,
    },

    {
      name: 'Iran',
      N: 35,
      E: 51,
    },

    {
      name: 'United States of America',
      N: 34.125447565116126,
      E: 241.7431640625,
    },
    {
      name: 'United Kingdom',
      N: 51.508742458803326,
      E: 359.82421875,
    },
    {
      name: 'Brazil',
      N:  -9.96885060854611,
      E: 668.1445312499999,
    },
  ]
}

]
