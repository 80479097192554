import React, { useContext } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';

function ETGB  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
        <div className="page-wrapper" >
        <div>
        <HeaderTopBar />
        <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
      </div>
    
      <div style={{width:"100%",textAlign:"center"}}>
      <div className='cirab'>  <img className='cirabinner' src={process.env.PUBLIC_URL + '/images/bg/etgbinner.png'} alt="Plane" /></div>

  
    </div>
    <div className="container classdesign">
    <div className="row">
      <div className="col-md-2 zra">
      <div className="vertical-bar">
      <div className="moving-square"></div>
    </div>
      </div>
  
      <div className="col-md-10">
        <div className="text-content">
        <h3 className="airtop">{translations.navtwofive}</h3>
        <p className='airbot'>
          {translations.servetfive}
          </p>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servet2sixtitle1}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servet2sixdesc1}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servet2sixtitle2}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servet2sixdesc2}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servet2sixtitle3}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servet2sixdesc3}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servet2sixtitle4}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servet2sixdesc4}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servet2sixtitle5}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servet2sixdesc5}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servet2sixtitle6}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servet2sixdesc6}</span>
            </li>
          </ul>
        </div>
      </div>
  

    </div>
  </div>
  <FooterFour></FooterFour>

      </div>

        </>
    )
}

export default ETGB;
