import React from "react";
import ScrollTop from "./ScrollTop";
import NewsletterOne from "./NewsletterOne";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import Logo2 from "../../elements/logo/Logo2";

const FooterFour = () => {
  return (
    <>
      <footer
        className="rn-footer footer-style-default no-border"
        style={{ position: "relative", zIndex: 4 }}
      >
        <div className="footer-top">
          <div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="logo xxxweee">
                    <Logo2
                      image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                      image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NewsletterOne
          newsletetrStyle="rn-newsletter-default"
          extraClass="border-top-bottom"
        />
        <div className="copyright-area copyright-style-one no-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-6 col-sm-12 col-12 rart">
                <div className="copyright-left">
                  <ul className="ft-menu link-hover">
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Terms And Condition</a>
                    </li>
                    <li>
                      <a href="/iletisim">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 cold-md-6 col-sm-12 col-12 rart">
                <ul className="social-icon social-default">
                <li>

                  <a
                    href="https://tr.linkedin.com/company/startlog-global-logistics"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiLinkedin />
                  </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/startlogturkey/?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiInstagram />
                    </a>
                  </li>
           
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12 rart">
                <div className="copyright-right text-center text-md-end">
                  <p className="copyright-text">
                    © Startlog {new Date().getFullYear()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterFour;
