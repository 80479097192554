import React, { useContext } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';

import FooterFour from 'common/footer/FooterFour';
import LanguageContext from '../LanguageContext';

function AboutUs  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
            <SEO title="Hakkımızda" />
            <main className="page-wrapper" style={{width:"100%"}}>
               
  <div>
  <HeaderTopBar />
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>
<div style={{position:"relative",zIndex:3,width:"100%",backgroundColor:"var(--color-dark)",paddingBottom:70,paddingTop:40}}>
<div className="container-fluid">
        <div className="row">
            <div className="col-md-6 col-12" style={{padding:0}}>
                <img className='imggaboutt' src={process.env.PUBLIC_URL + '/images/bg/aboutus2.png'} alt="Plane" />
            </div>
            <div className="col-md-6 col-12">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 vikkar">
                            <div >
                                <h1 style={{fontFamily:"var(--font-tt-lakes-neue-extended-bold)",color:"white"}} className="cort">{translations.aboutustitle}</h1>
                            </div>
                            <div className='spandivabout'>
                                <span className="spanaboutus">{translations.aboutuscontent}</span>
                            </div>
                            <div style={{backgroundColor:"var(--color-primary)",height:2,width:"20%",marginBottom:20}}></div>
                            <div className='spandivabout'>
                                <span className="spanaboutus">{translations.aboutuscontent2}</span>
                            </div>
                            <div className='spandivabout'>
                                <span className="spanaboutus">{translations.aboutuscontent3}</span>
                            </div>
                            <div className='spandivabout'>
                                <span className="spanaboutus">{translations.aboutuscontent4}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
                <FooterFour />
            </main>
        </>
    )
}

export default AboutUs;
