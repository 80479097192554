
import React from "react";
import CardSlider from "./cardslider";
const AstralServiceDsg = () => {




  return (
      <div className="unordcontainer">  
<div className="unordcontainerinner">
<div className="pipe" style={{display:"none"}}></div>


    <div className="moon">

<CardSlider/>
  </div>  
  </div>
  </div>
  );
};

export default AstralServiceDsg;


