// @mui material components
import Grid from "@mui/material/Grid";

// Admin panel React components
import MDBox from "components/MDBox"
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { CircularProgress, OutlinedInput, InputAdornment, IconButton, DialogActions, Dialog, DialogTitle, DialogContent, Typography, Box, TextField, InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import { green,red } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';

// Admin panel React example components
import React, { useState,useEffect } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";

// Data
import brandsNameTable from "layouts/brands/data/brandsNameTable";

//firestore 
import { db, storage } from "../../firebase"
import { collection, addDoc, getDocs, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



//modal Styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Brands() {

  const [editorData, setEditorData] = useState('<p>Buraya metin ekleyin...</p>');

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  
  const editorConfig = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'link', 'bulletedList', 'numberedList', 'horizontalLine', '|',
      'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify', '|',
      'fontSize', '|',
      'fontColor', 'fontBackgroundColor', '|', // Renk ayarları eklendi
      'indent', 'outdent', '|',
      'blockQuote', '|',
      'undo', 'redo'
    ],
    ckfinder: {
      uploadUrl: 'http://your-upload-url-here', // Resim yükleme URL'si
    },
  };
  
  const { columns, rows } = brandsNameTable();
  const [brandsModal, setBrandsModal] = React.useState(false);
  const [brandsNotification, setBrandsNotification] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [imageProgress, setImageProgress] = React.useState(0);
  const [imageProgressValue, setImageProgressValue] = React.useState(0);
  const [categoriesDropdown, setCategoriesDropdown] = React.useState([])

  const [brandsData, setBrandsData] = React.useState({
    id: '',
    title: '',
    body: [],
    date: '',
    image:'',
    largeImage:''
  })
  const [file, setFile] = useState(null);
  const [imagenamee, simagenamee] = React.useState('');
  const [lonenlyimagename, Setlonenlyimagename] = React.useState('');
  const maxFileSizeInBytes = 20 * 1024 * 1024; // 5MB

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  
    if (selectedFile) {
      if (selectedFile.size <= maxFileSizeInBytes) {
        setFile(selectedFile);
        const directoryPath = process.env.PUBLIC_URL+"images/blog/";
        const filename = selectedFile.name;
        const newFileName = filename.replace(/ğ/g, 'g').replace(/ü/g, 'u').replace(/ş/g, 's').replace(/ı/g, 'i').replace(/ö/g, 'o').replace(/ç/g, 'c').replace(/Ğ/g, 'G').replace(/Ü/g, 'U').replace(/Ş/g, 'S').replace(/I/g, 'I').replace(/Ö/g, 'O').replace(/Ç/g, 'C');
        const fullPath = directoryPath + newFileName;
        simagenamee(fullPath);
        Setlonenlyimagename(newFileName);
      } else {
        alert("Dosya çok büyük! Maksimum 20MB olmalı.");
      }
    }
  };
  const handleUpload = (degerdata) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('blog', 'blog');
      formData.append('dataname', lonenlyimagename);
      formData.append('degerdata', JSON.stringify(degerdata));
  
      fetch('https://mrtportservices.com:5000/uploadAndAddData?origin=startlog', {
        method: 'POST',
        body: formData
      })
        .then((response) => {
          if (response.status === 200) {
            alert('Veri başarıyla eklendi');
            brandsModalClose();
            window.location.reload();
          } else {
            alert('Veri eklenemedi');
          }
        })
        .catch((error) => {
          alert('Bir hata oluştu: ' + error);
        });
    }
  };

  const bubberer=()=>{

    if (!brandsData.title.trim()) {
      alert('Başlık alanı boş bırakılamaz');
      return; // Başlık alanı boşsa işlemi durdur
    }
    if (editorData && editorData !== '<p>Buraya metin ekleyin...</p>') {
      // editorData dolu ve özel bir değere sahip, burada istediğiniz işlemi yapabilirsiniz
    } else {
      alert('Lütfen metin ekleyin'); // Eğer soş veya varsayılan metne eşitse uyarı göster
    }
    if (!file) {
      alert('Lütfen bir dosya seçin'); // Eğer file boşsa, uyarı göster
      return;
    }
    const bugun = new Date();
    const gun = bugun.getDate();
    const turkceAylar = [
      'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
      'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ];
    const ay = turkceAylar[bugun.getMonth()];
    const yil = bugun.getFullYear();
    const birlesik=gun+" "+ay+" "+yil;


    const yeniVeri = {
      title: brandsData.title, 
      image:  imagenamee, // brandsData'nın image'ını kullanabilirsiniz
      largeImage: imagenamee, // brandsData'nın largeImage'ını kullanabilirsiniz
      date: birlesik,
      body: [editorData], 
   
    };
  

    handleUpload(yeniVeri);
  }
  
  



 

  const brandsModalOpen = () => setBrandsModal(true);
  const brandsModalClose = () => {
    setBrandsModal(false)
    setLoading(false)
    setError('')
    setImageProgress(0)
    setImageProgressValue(0)
  };
  const brandsNotificationOpen = () => setBrandsNotification(true);
  const brandsNotificationClose = () => setBrandsNotification(false);
  return (
    <>
      <MDSnackbar
        color="success"
        icon="check"
        title="Successfully Add"
        // content="Hello, world! This is a brandsNotification message"
        // dateTime="11 mins ago"
        open={brandsNotification}
        onClose={brandsNotificationClose}
        close={brandsNotificationClose}
      />
      <BootstrapDialog
        onClose={brandsModalClose}
        aria-labelledby="customized-dialog-title"
        open={brandsModal}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={brandsModalClose}>
          <Typography variant="h3" color="secondary.main" sx={{ pt: 1, textAlign: "center" }}>Haber-Duyuru Ekle</Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 2, maxWidth: "100%", display: "flex", direction: "column", justifyContent: "center" },
            }}
            noValidate
            autoComplete="off"
          >
          <div>
          <OutlinedInput
          placeholder="Başlığı buraya girin."
          value={brandsData.title}
            sx={{
              height: "2.8rem",
              padding: "8px",
              width: "100%",
              marginBottom: "10px", // Altındaki textarea ile aralarındaki boşluğu belirlemek için margin ekledik
            }}
            onChange={(e) =>
              setBrandsData({
                ...brandsData,
                title: e.target.value,
              })
            }
            id="outlined-adornment-amount"
          ></OutlinedInput>
          <div>
          <CKEditor
          editor={ClassicEditor}
          data={editorData}
          onChange={handleEditorChange}
          style={{ width: '100%', height: '500px' }}
          config={editorConfig}
        />
    </div>
        </div>
        
            <Box sx={{ maxWidth: "100%", m: 2 }}>
              <FormControl fullWidth sx={{ mt: 2 }} >
              

                <InputAdornment position="start" sx={{ marginTop: 2 }}>
            
                <input type="file" accept="image/*" onChange={handleFileChange} />
                  </InputAdornment>
             
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        size={25}
                        sx={{
                          color: green[500],
                        }}
                        value={imageProgress} />
                        <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {imageProgressValue === 100 ? <CheckIcon /> : null}
                      </Box>
                    </Box>

              </FormControl>
            </Box>
            {error === '' ? null :
              <MDBox mb={2} p={1}>
                <TextField
                  error
                  id="standard-error"
                  label="Error"
                  InputProps={{
                    readOnly: true,
                    sx: {
                      "& input": {
                        color: "red",
                      }
                    }
                  }}
                  // defaultValue="Invalid Data!"
                  value={error}
                  variant="standard"
                />
              </MDBox>}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          {loading ?
            <CircularProgress
              size={30}
              sx={{
                color: green[500],
              }}
            /> : <MDButton variant="contained" color="info" type="submit"
              // disabled={brandsData.name === '' || brandsData.contactNo === '' || brandsData.website === '' || brandsData.category === '' || brandsData.logo === '' ? true : false}
              onClick={bubberer}
            >Kaydet</MDButton>
          }
        </DialogActions>
      </BootstrapDialog>

      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDBox pt={2} pb={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h6" fontWeight="medium" color="white">
                        Tüm Haber-Duyurular
                      </MDTypography>
                      <MDButton variant="gradient" color="light"
                        onClick={() => {
                          brandsModalOpen()
                        }}>
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;HABER-DUYURU EKLE
                      </MDButton>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Brands;
