import React, { useState, useEffect } from "react";
import BlogListAstral from './BlogListAstral';


const BlogProp = ({ column, StyleVarProp }) => {

    const [visibleItems, setVisibleItems] = useState([]);

    useEffect(() => {
      
    
        fetchData(); // Veriyi ilk yükleme
  
      }, []);
    
      const fetchData = async () => {

        try {
          const response = await fetch("https://mrtportservices.com:5000/getData?blog=blog&origin=startlog"); // API endpointinizi buraya ekleyin
          const result = await response.json();
          //const reversedData = result.reverse();
          setVisibleItems(result.slice(0, 4));


        } catch (error) {
          console.error("Error fetching data:", error);
        }
      

      };


    return (
        <>
            <div className="col-lg-12">
                <div className="row row--15">
                    {visibleItems.map((item) => (
                        <div key={item.id} className={column}>
                            <BlogListAstral StyleVar={StyleVarProp} data={item} />
                        </div>
                    ))}
                </div>
            </div>
           
        </>
    )
}

export default BlogProp;
