import React, { useEffect, useState, useRef, useContext } from "react";
import LanguageContext from '../../LanguageContext';
import emailjs from 'emailjs-com';

const AstralContactt = () => {
  const [greenNotification, setGreenNotification] = useState(false);
  const [redNotification, setRedNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const showNotification = (isGreen, message = '') => {
    if (isGreen) {
      setGreenNotification(true);
      setTimeout(() => setGreenNotification(false), 3000);
    } else {
      setRedNotification(true);
      setErrorMessage(message);
      setTimeout(() => setRedNotification(false), 3000);
    }
  };

  const Notification = ({ color, message }) => {
    let boxShadowColor;
    if (color === "green") {
      boxShadowColor = "rgba(0, 128, 0, 0.7)";
    } else {
      boxShadowColor = "rgba(255, 0, 0, 0.7)";
    }
  
    const boxStyle = {
      background: color,
      boxShadow: `0 0 10px ${boxShadowColor}`,
    };
  
    return (
      <div style={boxStyle} className="notnot">
        {message}
      </div>
    );
  };


  const { translations } = useContext(LanguageContext);
  const [formData, setFormData] = useState({
    isim: "",
    email: "",
    telno: "",
    adress: "",
    message: "",
    city: "",
    startCity: "",
    select: "",
    incoterms: "",
    quantity: "",
    width: "",
    size: "",
    height: "",
  });

  const selectRightRef = useRef(null);
  const selectLeftRef = useRef(null);
  const [selectOpen, setSelectOpen] = useState({ right: false, left: false });

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (selectOpen.right && selectRightRef.current && !selectRightRef.current.contains(e.target)) {
        setSelectOpen({ ...selectOpen, right: false });
      }
      if (selectOpen.left && selectLeftRef.current && !selectLeftRef.current.contains(e.target)) {
        setSelectOpen({ ...selectOpen, left: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectOpen]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
      e.preventDefault();

    const requiredFields = ["isim", "email", "telno", "adress", "message", "city", "startCity", "select", "incoterms", "quantity", "width", "size", "height"];
    const missingFields = requiredFields.filter(field => !formData[field]);

    if (missingFields.length > 0) {
      showNotification(false, `Doldurulması gereken alanlar: ${missingFields.join(", ")}`);
      return;
    }

 
   
    fetch('https://mrtportservices.com:5000/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subject: formData.isim + " Yeni Teklif",
        text: JSON.stringify(formData, null, 2),
      }),
    })
    .then((addDataResponse) => {
      if (addDataResponse.status === 200) {
        showNotification(true, "Mesajınız gönderildi.");
      } else if (addDataResponse.status === 400) {
        showNotification(false, "2 Dakikada bir mesaj gönderebilirsiniz.");
      } else {
        showNotification(false, "Hata, mesaj gönderilemedi.");
      }
    })
    .catch(() => {
      showNotification(false, "Hata, mesaj gönderilemedi.");
    });
  };

  const toggleSelect = (direction) => {
    setSelectOpen({ ...selectOpen, [direction]: !selectOpen[direction] });
  };

  return (
    <div className="astralcontactmain">
      {greenNotification && (
        <Notification color="green" message="Mesajınız gönderildi ✔" />
      )}
      {redNotification && (
        <Notification color="red" message={errorMessage} />
      )}
      <div className="newbigdiv">
      <div className="headertutor">
      <h1 className="zzzxx" style={{color:"white"}}>{translations.offerformtitle}</h1>
        </div>
        <div className="newbigdivinner">

          <div className="service-container lefttex">
            <img src={process.env.PUBLIC_URL + '/images/bg/services.png'} className="service-background" alt="Background" />
          </div>
          <form className="contact-formm lefttex" onSubmit={handleSubmit}>
            <div >
              <span className="newakefontnew">{translations.offerformtitle1}</span>
            </div>
            <div className="form-row">
              <input
                type="email"
                placeholder={translations.mail}
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
              />
              <input type="text" placeholder={translations.name} name="isim" value={formData.isim} onChange={handleInputChange} />
            </div>
            <div className="form-row">
              <input type="text" placeholder={translations.adress} name="adress" value={formData.adress} onChange={handleInputChange} />
              <input
                type="number"
                placeholder={translations.phone}
                name="telno"
                value={formData.telno}
                onChange={handleInputChange}
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" // Telefon numarası formatı örneği, gerektiğinde değiştirilebilir
              />
            </div>
            <div >
              <span className="newakefontnew">{translations.offerformtitle2}</span>
            </div>
            <div className="form-row">
              <input type="text" placeholder={translations.city} name="city" value={formData.city} onChange={handleInputChange} />
              <input type="text" placeholder={translations.startcity} name="startCity" value={formData.startCity} onChange={handleInputChange} />
            </div>
            <div className="form-row">
              <div className="custom-select-wrapper" ref={selectRightRef} onClick={() => toggleSelect("right")}>
                <select
                  name="select"
                  value={formData.select}
                  onChange={handleInputChange}
                  className="custom-select"
                >
                  <option style={{ background: "black" }} value="">{translations.type}</option>
                  <option value={translations.type1}>{translations.type1}</option>
                  <option value={translations.type2}>{translations.type2}</option>
                  <option value={translations.type3}>{translations.type3}</option>
                  <option value={translations.type4}>{translations.type4}</option>
                  <option value={translations.type5}>{translations.type5}</option>
                  <option value={translations.type6}>{translations.type6}</option>
                  <option value={translations.type7}>{translations.type7}</option>
                  <option value={translations.type8}>{translations.type8}</option>
                  <option value={translations.type9}>{translations.type9}</option>
                  <option value={translations.type10}>{translations.type10}</option>
                </select>
                <span className="custom-arrow">{selectOpen.right ? '▲' : '▼'}</span>
              </div>
              <div className="custom-select-wrapper" ref={selectLeftRef} onClick={() => toggleSelect("left")}>
                <select
                  name="incoterms"
                  value={formData.incoterms}
                  onChange={handleInputChange}
                  className="custom-select"
                >
                  <option value="">Incoterms</option>
                  <option value="EXW">EXW</option>
                  <option value="FOB">FOB</option>
                  <option value="FCA">FCA</option>
                  <option value="DAP">DAP</option>
                  <option value="DDP">DDP</option>
                  <option value="CIF">CIF</option>
                  <option value="CPT">CPT</option>
                </select>
                <span className="custom-arrow">{selectOpen.left ? '▲' : '▼'}</span>
              </div>
              <div className="half-width-inputs">
                <div className="form-row" style={{ marginBottom: "0px" }}>
                  <input type="number" style={{ width: "100%" }} placeholder={translations.typelitteone} name="quantity" value={formData.quantity} onChange={handleInputChange} />
                  <input type="number" style={{ width: "100%" }} placeholder={translations.typelittetwo} name="width" value={formData.width} onChange={handleInputChange} />
                </div>
              </div>
              <div className="half-width-inputs">
                <div className="form-row" style={{ marginBottom: "0px" }}>
                  <input type="number" style={{ width: "100%" }} placeholder={translations.typelittethree} name="size" value={formData.size} onChange={handleInputChange} />
                  <input type="number" style={{ width: "100%" }} placeholder={translations.typelittefour} name="height" value={formData.height} onChange={handleInputChange} />
                </div>
              </div>
            </div>
            <textarea placeholder={translations.typelittefive} value={formData.message} name="message" onChange={handleInputChange}></textarea>
            <button className="custom-btnn btn-6" type="submit"><span><p>{translations.navsix}</p></span></button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AstralContactt;
