import React, { useState,useContext } from "react";
import LanguageContext from '../../LanguageContext';
import { Link } from "react-router-dom";

const CardSlider = () => {
  const [selectedCard, setSelectedCard] = useState("c1");
  const { translations } = useContext(LanguageContext);

  const handleCardClick = (cardId) => {
    setSelectedCard(cardId === selectedCard ? cardId : cardId);
  };
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };
  const cards = [

    {
      id: "c1",
      title: `${translations.navtwoone}`,
      description:  truncateText(`${translations.servairexp}`, 300),
      backgroundImage: `${process.env.PUBLIC_URL}/images/bg/planee2.png`,
      link: "/air-freight"

    },
    {
      id: "c2",
      title: `${translations.navtwotwo}`,
      description:  truncateText(`${translations.servshipexp}`, 300),
      backgroundImage: `${process.env.PUBLIC_URL}/images/bg/ship.png`,
      link: "/sea-freight"

    },
    {
      id: "c3",
      title: `${translations.navtwothree}`,
      description:  truncateText(`${translations.servroadexp}`, 300),

      backgroundImage: `${process.env.PUBLIC_URL}/images/bg/truck.png`,
      link: "/road-freight"

    },
    {
      id: "c4",
      title: `${translations.navtwofour}`,
      description:  truncateText(`${translations.servwarehouse}`, 300),

      backgroundImage: `${process.env.PUBLIC_URL}/images/bg/box.png`,
      link: "/warehouse"

    },
  ];
  const getBackgroundPosition = (cardId) => {
    switch (cardId) {
      case "c1":
        return "top";
      case "c2":
        return "top";
      case "c3":
        return "center";
      case "c4":
        return "bottom";
      default:
        return "center";
    }
  };
  return (
    <div className="wrapperstart">
      <div className="containerstart">
        {cards.map((card) => (
          <div
            key={card.id}
            className={`cardstart ${selectedCard === card.id ? "active" : ""} ${selectedCard === "c4" ? "margleft" : "margright"}`}
            onClick={() => handleCardClick(card.id)}
            style={{
              backgroundImage: `url(${card.backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: getBackgroundPosition(card.id),
            }}
          >
            <div className="row">
              <div className="icon">{card.id.charAt(1)}</div>
              <div className="description">
              <Link to={card.link}>
                <h4 style={{color:"white"}}>{card.title}</h4>
                </Link>
                <p>{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSlider;
