import React, {useContext } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';

function RoadFre  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
        <div className="page-wrapper" >
        <div>
        <HeaderTopBar />
        <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
      </div>
    
      <div style={{width:"100%",textAlign:"center"}}>
      <div>  <img className='rirab' src={process.env.PUBLIC_URL + '/images/bg/truck.png'} alt="Plane" /></div>

  
    </div>
    <div className="container classdesign">
    <div className="row">
    <div className="col-md-2 zra">
    <div class="vertical-bar">
    <div class="moving-square"></div>
  </div>
      </div>
  
      <div className="col-md-10">
        <div className="text-content">
          <h3 className="airtop">{translations.navtwothree}</h3>
          <p className='airbot'>
          {translations.servroadexp}</p>
        </div>
      </div>
  

    </div>
  </div>
  <FooterFour></FooterFour>

      </div>
        </>
    )
}

export default RoadFre;
