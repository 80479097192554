import React from 'react';
import SEO from '../common/SEO';
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import AstralServiceDsg from '../elements/astralservice/astralservo';
import AstralBlog from '../elements/astralservice/astralblogfrontal';
import AstralContactt from '../elements/astralservice/astralconta';
import FooterFour from '../common/footer/FooterFour';
import LoadingComponent from './componentone';

function Finance() {




  return (
    <>
      <SEO title="Finance" />
      <main className="page-wrapper">
        <div>
          <HeaderTopBar />
          <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="rn-header header-default header-transparent header-default " Loco="main" />
        </div>
        <LoadingComponent/>


        <AstralServiceDsg />
        <AstralContactt />
        <AstralBlog />
        <FooterFour />
      </main>
    </>
  );
}

export default Finance;
