import React, { useContext } from 'react';
import ContactForm from "./ContactForm";
import LanguageContext from '../../LanguageContext';

const ContactOne = () => {
    const { translations } = useContext(LanguageContext);

    return (
        <>
       
            <div className="row">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30 arrayze" >
                <div className="full-width-div" style={{marginTop:"-50px"}}>
                <img  className='side-image' src={process.env.PUBLIC_URL + '/images/bg/phonered.png'} style={{width:"90px", height:"auto"}} alt="Background"/>

                    <div >
                        <h4 className="contactheaderds" style={{margin:0,padding:0,marginBottom:15,lineHeight:0.1,marginTop:5}} >{translations.contactphone}</h4>
                        <p style={{margin:0,padding:0}} className="contactheaderp" ><a style={{color:"var(--color-body)"}} href="tel:+90 506 046 00 14">+90 216 606 03 71</a></p>
                        <p style={{margin:0,padding:0}} className='contactheaderp' ><a style={{color:"var(--color-body)"}} href="tel:+90 541 744 77 43">+90 534 505 13 71</a></p>
                    </div>
                </div>

                <div className="full-width-div">
                <img className='side-image'  src={process.env.PUBLIC_URL + '/images/bg/email.png'} style={{width:"90px", height:"auto"}} alt="Background"/>

                    <div style={{paddingBottom:35,display:"flex",flexDirection:"column",justifyContent:"flex-start"}}> 
                        <h4 style={{margin:0,padding:0,marginBottom:15,lineHeight:0.1,marginTop:35}} className="contactheaderds">{translations.contactemail}</h4>
                        <p style={{margin:0,padding:0}} className='contactheaderp'><a style={{color:"var(--color-body)"}} href="mailto:yesim.guzel@startlog.com.tr">yesim.guzel@startlog.com.tr</a></p>
                        <p style={{margin:0,padding:0}} className='contactheaderp'><a style={{color:"var(--color-body)"}} href="mailto:info@startlog.com.tr">info@startlog.com.tr</a></p>
                        <p style={{margin:0,padding:0}} className='contactheaderp'><a style={{color:"var(--color-body)"}} href="mailto:sales@startlog.com.tr">sales@startlog.com.tr</a></p>

                     
                    </div>
                </div>

                <div className="full-width-div">
                <a href="https://www.google.fr/maps/place/Anel+%C4%B0%C5%9F+Merkezi/@41.025451,29.1125381,17z/data=!4m10!1m2!2m1!1ssaray+mahallesi+siteyolu+caddesi+no:5+anel+is+merkezi+kat+11+no+28+google+adres!3m6!1s0x14cac8e759a26def:0xa757420aed264711!8m2!3d41.025447!4d29.115113!15sCk9zYXJheSBtYWhhbGxlc2kgc2l0ZXlvbHUgY2FkZGVzaSBubzo1IGFuZWwgaXMgbWVya2V6aSBrYXQgMTEgbm8gMjggZ29vZ2xlIGFkcmVzIgOIAQGSARBjb3Jwb3JhdGVfb2ZmaWNl4AEA!16s%2Fg%2F11fylw0kff?entry=ttu" 
                      target="_blank" rel="noopener noreferrer"><img className='side-image' src={process.env.PUBLIC_URL + '/images/bg/gps.png'} style={{width:"70px", height:"auto"}} alt="Background"/>
                      </a>

                      <div style={{paddingBottom:35,display:"flex",flexDirection:"column",justifyContent:"flex-start",marginLeft:42,marginTop:21}}> 
                        <h4 style={{margin:0,padding:0,marginBottom:5}} className="contactheaderds">{translations.contactadress}</h4>
                      <p  style={{margin:0,padding:0}}>  <a className='contactheaderp' style={{color:"var(--color-body)"}} href="https://www.google.fr/maps/place/Anel+%C4%B0%C5%9F+Merkezi/@41.025451,29.1125381,17z/data=!4m10!1m2!2m1!1ssaray+mahallesi+siteyolu+caddesi+no:5+anel+is+merkezi+kat+11+no+28+google+adres!3m6!1s0x14cac8e759a26def:0xa757420aed264711!8m2!3d41.025447!4d29.115113!15sCk9zYXJheSBtYWhhbGxlc2kgc2l0ZXlvbHUgY2FkZGVzaSBubzo1IGFuZWwgaXMgbWVya2V6aSBrYXQgMTEgbm8gMjggZ29vZ2xlIGFkcmVzIgOIAQGSARBjb3Jwb3JhdGVfb2ZmaWNl4AEA!16s%2Fg%2F11fylw0kff?entry=ttu" 
                      target="_blank" rel="noopener noreferrer">Saray Mah. Siteyolu Cad. No:5 Anel Is Merkezi Kat:11 No:28<br /> ÜMRANİYE / İSTANBUL</a></p>
                       
                    </div>
                </div>z
</div>
            </div>
        </>
    )
}
export default ContactOne;