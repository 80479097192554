import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";


const BlogList = ({ data, StyleVar }) => {
    function limitContentByWords(content, limit) {
        const words = content.split(' ');
        const limitedWords = words.slice(0, limit);
        return limitedWords.join(' ');
      }
      
      // Kullanım örneği
      const limitedContent = limitContentByWords(data.body, 15);
    
    return (
        <div className={`rnn-card ${StyleVar}`}>
        <div className="inner">
            <div className="thumbnail">

                <Link to={process.env.PUBLIC_URL + `/blog-detay/${data.id}`} className="image">
                <img src={`${process.env.PUBLIC_URL}/${data.image}`} alt="Blog Image" className="innerbreaker" />
                </Link>
                </div>

            <div className="content">

                    <span className='datespan'  style={{fontSize:"18px"}}>{data.date}</span>
    
                <h4 style={{color:"black",fontFamily:"var(--font-tt-lakes-neue-trial-bold)",fontSize:25,margin:0,padding:0}} >
                    <Link to={process.env.PUBLIC_URL + `/blog-detay/${data.id}`}>
                        {data.title}
                    </Link>
                </h4>

                <div className='spaninnerhtmlk'  dangerouslySetInnerHTML={{__html: limitedContent}}></div>

            </div>
        </div>
    </div>
    )
}
BlogList.propTypes = {
    data: PropTypes.object
};
export default BlogList;
