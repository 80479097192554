import React,{useContext} from 'react'

import LanguageContext from '../../LanguageContext.js';

const NewsletterOne = ({extraClass, newsletetrStyle}) => {
    const { translations } = useContext(LanguageContext);

    return (
        <div className={`newsletter-area ${newsletetrStyle}`}>
            <div className="container">
                <div className={`row row--0 newsletter-wrapper align-items-center ${extraClass}`}>
                    <div className="col-lg-4">
                        <div className="newsletter-section-title">
                            <h3 className="title" style={{color:"white"}}>{translations.fottertitle}</h3>
                            <p className="description">{translations.fottercontent}</p>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <form className="rn-newsletter mt_md--20 mt_sm--20" action="#">
                            <div className="form-group">
                                <input style={{backgroundColor:"rgba(255,255,255,.4)",color:"black"}} type="email" placeholder="Email Address" />
                            </div>
                            <div className="form-group">
                                <button className="btn-default">{translations.fotterbutton}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewsletterOne;
