import React, { useMemo, useState, useEffect,useContext } from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import ReactPaginate from 'react-paginate';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import jsonData from '../assets/images/airports_iata_codes.json'; // JSON dosyasını içe aktarın
import { GoArrowLeft,GoArrowRight } from "react-icons/go";

const data = [
  {
    iata_code: "AAA",
    longitude: "-145.5099945",
    region: "Tuamotus",
    time_offset: "(GMT -10:00)",
    country: "French Polynesia",
    name: "Anaa Airport",
    iso_country: "PF",
    latitude: "-17.3526001",
    city: "Anaa",
    timezone: "Pacific/Tahiti",
    country_area_code: "689"
  },
  // Daha fazla veri ekleyin
];

const AirCodes = () => {
  const columns = useMemo(
    () => [
      { Header: 'Şehir', accessor: 'city' },
      { Header: 'Havalimanı Kodu', accessor: 'iata_code' },
      { Header: 'Havalimanı', accessor: 'name' },
      { Header: 'Ülke', accessor: 'country' },
      { Header: 'Ülke Kodu', accessor: 'iso_country' },
      { Header: 'Ülke Alan Kodu', accessor: 'country_area_code' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    gotoPage,
    pageCount,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    { columns, data: jsonData, initialState: { pageIndex: 0, pageSize: 10 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleSearch = (e) => {
    setGlobalFilter(e.target.value || undefined);
  };

  return (
    <>
        <div className="page-wrapper" >
        <div>
        <HeaderTopBar />
        <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
      </div>
    
      <div >
      <div style={{display:'flex',flexDirection:"row",justifyContent:"space-between",alignItems:"centerz",marginTop:50}}>
      <div style={{display:'flex',flexDirection:"row",paddingLeft:30,paddingRight:20,width:"40%"}}>
      <span className='mozboxwhite'>Sayfada</span>
      <select
            style={{width:60,marginLeft:10,marginRight:10,height:30,color:"black",padding:0,display:"flex",justifyContent:"center",flexDirection:"row",textAlign:"center"}}
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
            {pageSize}
            </option>
          ))}
        </select>
        <span className='mozboxwhite'>Kayıt Göster</span>

        </div>
        <div style={{width:"25%",paddingRight:30}}>
      <input
        value={globalFilter || ""}
        onChange={handleSearch}
        placeholder="Ara..."
        style={{ marginBottom: '10px',border:"1px solid transparent",borderRadius:10}}
      />
      </div>
      </div>
      <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    borderBottom: 'solid 3px var(--color-primary)',
                    background: 'aliceblue',
                    color: 'black',
                    fontWeight: 'bold',
                    textAlign:"center"
                  }}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: '10px',
                      border: 'solid 1px gray',
                      background: 'papayawhip',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",paddingLeft:30,paddingRight:30,marginTop:15,marginBottom:60}}>
      <span className='mozboxwhite'>
          Sayfa{' '}
          <strong>
            {pageIndex + 1} of {pageCount}
          </strong>{' '}
        </span>
        <div style={{display:"flex",height:50}}>
        <div style={{width:125,minWidth:125,maxWidth:125,padding:10,border:"1px solid transparent",height:"auto",backgroundColor:"white",color:"black",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",border:"1x solid transparent",borderRadius:10,cursor:"pointer"}} onClick={() => previousPage()} disabled={!canPreviousPage}>
          <GoArrowLeft color='black' size={20}></GoArrowLeft>
          <span style={{marginLeft:5}}>Previous</span>
        </div>
        <div style={{width:125,minWidth:125,maxWidth:125,padding:10,border:"1px solid transparent",height:"auto",backgroundColor:"white",color:"black",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",border:"1x solid transparent", borderRadius:10,marginLeft:20,cursor:"pointer"}} onClick={() => nextPage()} disabled={!canNextPage}>
          <span style={{marginRight:5}}>Next</span>
          <GoArrowRight color='black' size={20}></GoArrowRight>

        </div>
        </div>
   
      </div>

    </div>
  <FooterFour></FooterFour>

      </div>

        </>
  );
};

export default AirCodes;
