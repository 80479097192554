import React from "react";
import BlogGridViewAstral from "../../components/blog/BlogGridViewAstral"

const AstralBlog = () => {

return(
    
    <div className="astralblogmain">
    <div >
  
    </div>
    <div style={{width:"100%;"}}>  <BlogGridViewAstral></BlogGridViewAstral></div>

    </div>
    );

}


export default AstralBlog;

