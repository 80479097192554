import React, { useContext } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';

function Consult  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
        <div className="page-wrapper" >
        <div>
        <HeaderTopBar />
        <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
      </div>
    
      <div style={{width:"100%",textAlign:"center"}}>
      <div>  <img className='virab' src={process.env.PUBLIC_URL + '/images/bg/consult.png'} alt="Plane" /></div>

  
    </div>
    <div className="container classdesign">
    <div className="row">
      <div className="col-md-2 zra">
      <div className="vertical-bar">
      <div className="moving-square"></div>
    </div>
      </div>
  
      <div className="col-md-10">
        <div className="text-content">
        <h3 className="airtop">{translations.navtwosix}</h3>
        <p className='airbot'>
          {translations.servetsix}
          </p>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servetsixtitle1}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servetsixdesc1}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servetsixtitle2}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servetsixdesc2}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servetsixtitle3}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servetsixdesc3}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servetsixtitle4}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servetsixdesc4}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servetsixtitle5}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servetsixdesc5}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servetsixtitle6}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servetsixdesc6}</span>
            </li>
          </ul>
          <ul>
            <li style={{color:"var(--color-primary)",fontFamily:"var(--font-tt-lakes-neue-trial-bold)"}}><span className='leftultitle'>{translations.servetsixtitle7}</span>
            <span style={{color:"var(--color-body)",marginLeft:10,fontFamily:"var(--font-tt-lakes-neue-trial-regular)"}}>{translations.servetsixdesc7}</span>
            </li>
          </ul>
        </div>
      </div>
  

    </div>
  </div>
  <FooterFour></FooterFour>

      </div>

        </>
    )
}

export default Consult;
