// Material Dashboard 2 React layouts
import Dashboard from "./layouts/dashboard";
import Brands from "./layouts/brands";
import Banks from "./layouts/banks";

import Notifications from "./layouts/notifications/Notifications";
import OfferoNotifications from "./layouts/offermessages/OfferNotifications";

import { FaHandshake } from 'react-icons/fa';


//auth routes
import BrandsDetail from "./layouts/brands/components/Detail"


// @mui icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FeedIcon from '@mui/icons-material/Feed';
import CollectionsIcon from '@mui/icons-material/Collections';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import * as React from 'react'
import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";

const AdminAuthRoutes = ({ children }) => {
  const { role } = useContext(AuthContext)
      console.log("children:" + children)
      console.log(role)

  return role === "admin" ? children : <Navigate to="/login" />
}

const routes = [
  {
    routeRole: "admin",
    type: "collapse",
    name: "Anasayfa",
    key: "admin/dashboard",
    icon: <DashboardIcon />,
    route: "/admin/dashboard",
    component: <AdminAuthRoutes><Dashboard /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "collapse",
    name: "Haberler-Duyurular",
    key: "admin/haberler",
    icon: <NewspaperIcon />,
    route: "/admin/haberler",
    component: <AdminAuthRoutes><Brands /></AdminAuthRoutes>,
  },

  {
    routeRole: "admin",
    type: "collapse",
    name: "Resimler",
    key: "admin/resimler",
    icon: <CollectionsIcon />,
    route: "/admin/resimler",
    component: <AdminAuthRoutes><Banks /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "collapse",
    name: `Teklif Mesajları`,
    key: "admin/mesajlar",
    icon: <FaHandshake />,
    route: "/admin/offers",
    component: <AdminAuthRoutes><OfferoNotifications /></AdminAuthRoutes>,
  },


  {
    routeRole: "admin",
    type: "collapse",
    name: `Mesajlar`,
    key: "admin/mesajlar",
    icon: <NotificationsActiveIcon />,
    route: "/admin/mesajlar",
    component: <AdminAuthRoutes><Notifications /></AdminAuthRoutes>,
  },

 
]

const authRoutes = [
  {
    routeRole: "admin",
    type: "authRoutes",
    route: "/admin/haberler/detay/:id",
    component: <AdminAuthRoutes><BrandsDetail /></AdminAuthRoutes>,
  },
  
 
  


]
export default routes;
export { authRoutes }
