import React from 'react';


const SectionTitle = ({textAlign}) => {
    return (
        <div className={`section-title ${textAlign}`}>
      
        </div>
    )
}
export default SectionTitle;