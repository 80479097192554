import React from "react";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiMapPin,
  FiPhone,
} from "react-icons/fi";
import { Link } from "react-router-dom";

const HeaderTopBar = () => {
  return (
    <div className="header-top-bar">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-12 col-12">
            <div className="header-left"></div>
          </div>
          <div className="col-lg-8 col-md-12 col-12">
            <div className="header-right">
              <div className="address-content">
              <div className="btn-cont" style={{lineHeight:"initial",height:"initial",fontSize:"15px"}}>
              <FiMapPin />

              <a href="https://www.google.fr/maps/place/Anel+%C4%B0%C5%9F+Merkezi/@41.025451,29.1125381,17z/data=!4m10!1m2!2m1!1ssaray+mahallesi+siteyolu+caddesi+no:5+anel+is+merkezi+kat+11+no+28+google+adres!3m6!1s0x14cac8e759a26def:0xa757420aed264711!8m2!3d41.025447!4d29.115113!15sCk9zYXJheSBtYWhhbGxlc2kgc2l0ZXlvbHUgY2FkZGVzaSBubzo1IGFuZWwgaXMgbWVya2V6aSBrYXQgMTEgbm8gMjggZ29vZ2xlIGFkcmVzIgOIAQGSARBjb3Jwb3JhdGVfb2ZmaWNl4AEA!16s%2Fg%2F11fylw0kff?entry=ttu" 
              target="_blank" rel="noopener noreferrer" className="btnn newakefont">
              Ümraniye / İSTANBUL
                <span className="line-2"></span>
                <span className="line-4"></span>
              </a>
            </div>

            <div className="btn-cont" style={{lineHeight:"initial",height:"initial",fontSize:"15px"}}>
            <FiPhone />

            <a href="tel:+905060460014" 
            target="_blank" rel="noopener noreferrer" className="btnn newakefont">
            +90 216 606 03 71
              <span className="line-2"></span>
              <span className="line-4"></span>
            </a>
          </div>
          
              </div>
              <div className="social-icon-wrapper">
                <ul className="social-icon social-default icon-naked">
                <li>
                    <Link to="https://tr.linkedin.com/company/startlog-global-logistics">
                      <FiLinkedin />
                    </Link>
                  </li>


                  <li>
                    <Link to="https://www.instagram.com/startlogturkey/?hl=en">
                      <FiInstagram />
                    </Link>
                  </li>
             
              
             
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopBar;
