import {useState, useRef,useContext} from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import useStickyHeader from "./useStickyHeader";
import { useNavigate } from 'react-router-dom';
import LanguageContext from '../../LanguageContext.js';


const HeaderOne = ({ HeaderSTyle,Loco}) => {
    const { translations } = useContext(LanguageContext);

    const navigate = useNavigate();

    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const ref = useRef();
    const sticky = useStickyHeader( 50 );
    const headerClasses = `header-default ${(sticky ) ? 'sticky' : ''}`
        /* <Darkmode />*/

  
    const scrollDown = () => {
        if(Loco==="main"){
            window.scrollTo({ top: 1400, left: 0, behavior: 'smooth' });
        }
        else{
            navigate('/getoffer'); // '/getoffer' sayfasına yönlendir

        }
      };
    return (
        <>
            <header ref={ref} className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}>
                <div>
                    <div className="row align-items-center row--0">
                        <div className="col-lg-3 col-md-6 col-6 vixad" >
                            <Logo 
                                image={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                                image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                            />
                        </div>
                        <div className="col-lg-9 col-md-6 col-6 position-static dixad padi ">
                            <div className="header-right">
                                <nav className="mainmenu-nav damboy">
                                    <Nav />
                                </nav>
                          
                                <div className="mobile-menu-bar ml--5 damboyx">
                                    <div className="hamberger">
                                        <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                    </div>
                                </div>
                                <div className="damboy cabcap" style={{ padding: 0, paddingRight:"20px"}}>                    
  <button onClick={scrollDown}><span>{translations.navsix}</span></button>
  <div className="rxxxsda" >  <span style={{ lineHeight: "20px", wordWrap: 'break-word',width:"90%"}}>{translations.navsixt}</span></div>

</div>
                            </div>  
  
                        </div>
         
                    </div>
                </div>
             
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler}  />
        </>
    )
}
export default HeaderOne;