import React, { useContext } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';

function Dangereus  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
        <div className="page-wrapper"  style={{backgroundColor:"white"}}>
        <div>
        <HeaderTopBar />
        <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
      </div>
    
      <div style={{width:"100%",textAlign:"center"}}>
      <div>  <img className='virab' src={process.env.PUBLIC_URL + '/images/bg/planee2.png'} alt="Plane" /></div>

  
    </div>
    <div className="container-xxl dwsxxasaddawqe">
    <h1 className='sdw3eq'>SINIF 1: PATLAYICILAR</h1>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-48a181f elementor-section-boxed elementor-section-height-default snipcss0-0-0-1 snipcss-7dLnY" data-id="48a181f" data-element_type="section">
    <div className="elementor-container elementor-column-gap-default snipcss0-1-1-2" >
        <div className="elementor-row snipcss0-2-2-3" >
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54679e9 snipcss0-3-3-4" data-id="54679e9" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/1-tehlikeli-madde.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">Alt Sınıf 1.1</h4>
                                        <p className="continnerx1122212dbigsubbb">Yüksek patlama riski olan patlayıcılar. Yüksek patlama riski tüm yükü aynı anda etkiler.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-3938d48 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-15" data-id="3938d48" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-15-16" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-17-18" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/2-tehlikeli-madde.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">Alt Sınıf 1.2</h4>
                                        <p className="continnerx1122212dbigsubbb">Direkt değil de bağlantılı yoldan patlama riskiyle (saçılma, dağılma vs. ) etkili maddelerdir. Yüksek patlama riski içermezler.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-ae28fe6 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-23" data-id="ae28fe6" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-23-24" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-25-26" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/3-tehlikeli-madde.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">Alt Sınıf 1.3</h4>
                                        <p className="continnerx1122212dbigsubbb">Daha çok yangın riski olan maddelerdir. Yüksek ya da bağlantılı patlama riskleri düşüktür.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0f3899c snipcss0-3-3-31" data-id="0f3899c" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-31-32" >
                    <div className="elementor-widget-wrap snipcss0-5-32-33" >
                        <div className="elementor-element elementor-element-0006692 elementor-widget elementor-widget-single-icon-item snipcss0-6-33-34" data-id="0006692" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-34-35" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-36-37" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/4-tehlikeli-madde.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">Alt Sınıf 1.4</h4>
                                        <p className="continnerx1122212dbigsubbb">Patlama riski düşük maddelerdir. Patlama riski daha çok içerisinde bulundukları paketle sınırlıdır. Dış bir yangın tehdidinde paket içindeki maddeler patlama riski içermez.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-4cab8c3 elementor-widget elementor-widget-single-icon-item snipcss0-6-33-42" data-id="4cab8c3" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-42-43" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-44-45" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/1-tehlikeli-madde.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">Alt Sınıf 1.5</h4>
                                        <p className="continnerx1122212dbigsubbb">Dış etkilerden bağımsız patlayıcılar. Bu gruptaki maddeler yüksek patlama riskine sahip olsalar da normal taşıma koşullarında herhangi bir şekilde etkilenip patlamaları riski çok düşüktür.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-e00b8be elementor-widget elementor-widget-single-icon-item snipcss0-6-33-50" data-id="e00b8be" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-50-51" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-52-53"  >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/2-tehlikeli-madde.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter " >
                                        <h4 className="continnerx1122212dbigheader">Alt Sınıf 1.6</h4>
                                        <p className="continnerx1122212dbigsubbb">Aşırı derecede duyarsız maddelerdir, yüksek patlama riskleri bulunmamaktadır. Bu sınıfta patlamayı tetikleyici maddeler bulunmaktadır. Bunların kazayla patlama riski bulunmamaktadır.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
   <h1 className='sdw3eq'>SINIF 2: GAZLAR</h1>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-48a181f elementor-section-boxed elementor-section-height-default snipcss0-0-0-1 snipcss-7dLnY" data-id="48a181f" data-element_type="section">
    <div className="elementor-container elementor-column-gap-default snipcss0-1-1-2" >
        <div className="elementor-row snipcss0-2-2-3" >
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54679e9 snipcss0-3-3-4" data-id="54679e9" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/1-4-tehlikeli-madde.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">Alt Sınıf 2.1 Yanıcı Gazlar</h4>
                                        <p className="continnerx1122212dbigsubbb">454 kg (1001 lbs) olan ve at 20°C (68°F) altında gaz halinde bulunan maddeler. Bu maddelerin basınçları 101.3 kPa (14.7 psi) dir ve bu basınç altında kaynama noktaları 20°C (68°F) veya altıdır. 1. 101.3 kPa (14.7 psi) basınçta ve hava karışımları %13 altında parlayıcıdırlar. 2. Veya alt limit gözetilmeksizin en az % 12 hava karışımında ve 101.3 kPa (14.7 psi) basınçta yanıcıdırlar.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-3938d48 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-15" data-id="3938d48" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-15-16" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-17-18" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/2-tehlikeli-madde.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">Alt Sınıf 2.2 Yanıcı Olmayan, Zehirli Olmayan Gazlar</h4>
                                        <p className="continnerx1122212dbigsubbb">Direkt değil de bağlantılı yoldan patlama riskiyle (saçılma, dağılma vs. ) Bu sınıfta basınçlı gazlar, sıvılaştırılmış gazlar, basınçlı kryojenik gazlar, bir çözelti içinde bulunan sıkıştırılmış gazlar ve okside edici gazlar bulunmaktadır. Yanıcı ve zehirli olmayan gazlar 20°C (68°F) sıcaklıkta 280 kPa (40.6 psia) basınç muhteviyatı olan 2.1 ve 2.3 sınıfına dahil olmayan gazlardır) etkili maddelerdir. Yüksek patlama riski içermezler.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                  
                    </div>
                </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0f3899c snipcss0-3-3-31" data-id="0f3899c" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-31-32" >
                    <div className="elementor-widget-wrap snipcss0-5-32-33" >
                        <div className="elementor-element elementor-element-0006692 elementor-widget elementor-widget-single-icon-item snipcss0-6-33-34" data-id="0006692" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-34-35" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-36-37" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/3-tehlikeli-madde.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">Alt Sınıf 2.2 Oksijen Gazı</h4>
                                        <p className="continnerx1122212dbigsubbb">Bu işaret 2.2 sınıfı için isteğe bağlı bir etikettir. Gaz veya sıvı halde oksijen için kullanılır. Oksijen kendi halinde yanıcı değildir yalnız ortamda yanma olması için bulunması gerekir. Yüksek oksijen konsantrasyonları patlama riskini ve gücünü arttırır.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-4cab8c3 elementor-widget elementor-widget-single-icon-item snipcss0-6-33-42" data-id="4cab8c3" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-42-43" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-44-45" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/1-tehlikeli-madde.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">Alt Sınıf 2.3 Zehirli Gazlar</h4>
                                        <p className="continnerx1122212dbigsubbb">Zehirli gazlar 20°C ve altı sıcaklıkta, 101.3 kPa basınçta bulunan (bu basınç altında kaynama noktaları 20°C veya altı olan) 1. İnsan sağlığına zararlı olduğu bilinen ve taşıma sırasında sağlık tehlikesi yaratan 2. İnsan sağlığına zararları kesin olarak kanıtlanmış olmasa da, hayvanlar üzerinde yapılan testlerde LC50 değeri 5000 ml/m3 üzerinde olan maddelerdir.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
   <h1 className='sdw3eq'>SINIF 3: YANICI SIVILAR</h1>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-48a181f elementor-section-boxed elementor-section-height-default snipcss0-0-0-1 snipcss-7dLnY" data-id="48a181f" data-element_type="section">
    <div className="elementor-container elementor-column-gap-default snipcss0-1-1-2" >
        <div className="elementor-row snipcss0-2-2-3" >
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54679e9 snipcss0-3-3-4" data-id="54679e9" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/sivi.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 3: YANICI SIVI</h4>
                                        <p className="continnerx1122212dbigsubbb">454 kg (1001 lbs) olan ve at 20°C (68°F) altında gaz halinde bulunan maddeler. Bu maddelerin basınçları 101.3 kPa (14.7 psi) dir ve bu basınç altında kaynama noktaları 20°C (68°F) veya altıdır. 1. 101.3 kPa (14.7 psi) basınçta ve hava karışımlari %13 altında parlayıcıdırlar. 2. Veya alt limit gözetilmeksizin en az % 12 hava karışımında ve 101.3 kPa (14.7 psi) basınçta yanıcıdırlar.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
             
                  
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</section>
  <h1 className='sdw3eq'>SINIF 4: YANICI KATILAR VE DİĞER MADDELER</h1>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-48a181f elementor-section-boxed elementor-section-height-default snipcss0-0-0-1 snipcss-7dLnY" data-id="48a181f" data-element_type="section">
    <div className="elementor-container elementor-column-gap-default snipcss0-1-1-2" >
        <div className="elementor-row snipcss0-2-2-3" >
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54679e9 snipcss0-3-3-4" data-id="54679e9" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/KATI-1.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 4.1 YANICI KATILAR VE DİĞER MADDELER</h4>
                                        <p className="continnerx1122212dbigsubbb">Sınıf 1’e dahil olan fakat etkinliği alınmış patlayıcılar veya üretici tarafından özellikle bu sınıfa dahil edilmiş maddelerdir. Termal olarak dengesiz olan, hava katılımı olmadan güçlü ekzotermik tepkimeye giren ve kendinden tutuşabilen maddeler de bu kategoridedirler. Olduğu haliyle yanıcı olan katılar. Bu maddeler sürtünme yoluyla ateş alabilirler ve yanma hızları saniyede 2.2 mm (0.087 inch)den daha fazladır. Tutuşabilen ve tamamı 10 dakika veya altı bir zamanda tepkimeye giren metal tozları da bu sınıfa dahildir.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
             
                  
                    </div>
                </div>
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/KATI-2.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 4.2 YANICI KATILAR</h4>
                                        <p className="continnerx1122212dbigsubbb">Kendiliğinden alev alan maddeler piroforik maddelerdir. Bunlar, hava ile temasın beşinci dakikasında ateş alan ya da hava ile temas ettiklerinde ek bir enerji kaynağına ihtiyaç duymadan ısınan maddelerdir.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
             
                  
                    </div>
                </div>
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/KATI-3.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 4.3 Suyla temaslarında yanıcı gaz çıkaran maddeler</h4>
                                        <p className="continnerx1122212dbigsubbb">Islandığında Tehlikeli Maddeler olarak da adlandırılan bu maddeler suyla temas halinde yanıcı ya da zehirli maddeler çıkaran maddelerdir. Tehlike ölçüsü 1 kg madde için saatte 1 litreden fazla gaz çıkarmaktır.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
             
                  
                    </div>
                </div>
            </div>
           
        
        </div>
    </div>
</section>
    <h1 className='sdw3eq'>SINIF 5: OKSİJEN SALGILAYARAK YANMA RİSKİNİ ARTTIRAN MADDELER</h1>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-48a181f elementor-section-boxed elementor-section-height-default snipcss0-0-0-1 snipcss-7dLnY" data-id="48a181f" data-element_type="section">
    <div className="elementor-container elementor-column-gap-default snipcss0-1-1-2" >
        <div className="elementor-row snipcss0-2-2-3" >
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54679e9 snipcss0-3-3-4" data-id="54679e9" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/5.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 5.1 OKSİJEN SALGILAYARAK YANMA RİSKİNİ ARTTIRAN MADDELER</h4>
                                        <p className="continnerx1122212dbigsubbb">Bu tür maddeler oksijen salgılayarak diğer maddelerin yanmasını sağlayan veya çabuklaştıran maddelerdir.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0f3899c snipcss0-3-3-31" data-id="0f3899c" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-31-32" >
                    <div className="elementor-widget-wrap snipcss0-5-32-33" >
                        <div className="elementor-element elementor-element-0006692 elementor-widget elementor-widget-single-icon-item snipcss0-6-33-34" data-id="0006692" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-34-35" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-36-37" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/5-2.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 5.2 Organik peroksitler – çogu çabuk alev alir ve darbe ve sürtünmeye duyarlidirlar</h4>
                                        <p className="continnerx1122212dbigsubbb">Organik peroksitler (Sınıf 5.2) O-O halinde oksijen bulunduran maddelerdir. Bunlar hidrojen peroksidin bir türevi olarak düşünülebilirler, sudaki bir ya da daha fazla hidrojen atomunun organik radikallerle yer değiştirmesi yoluyla üretilirler.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
               
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  <h1 className='sdw3eq'>SINIF 6: TOKSİK MADDELER</h1>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-48a181f elementor-section-boxed elementor-section-height-default snipcss0-0-0-1 snipcss-7dLnY" data-id="48a181f" data-element_type="section">
    <div className="elementor-container elementor-column-gap-default snipcss0-1-1-2" >
        <div className="elementor-row snipcss0-2-2-3" >
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54679e9 snipcss0-3-3-4" data-id="54679e9" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/6.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 6.1 Toksik, zehirli maddeler</h4>
                                        <p className="continnerx1122212dbigsubbb">Taşıma sırasında insanlara zarar verebileceği bilinen maddeler toksik maddeler olarak sınıflandırılırlar. Ayrıca hayvanlar üzerinde yapılan testlerde de zehirli olduğu belirlenen maddeler insanlar için de tehlikeli sayılıp bu kategoriye dahil edilirler.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0f3899c snipcss0-3-3-31" data-id="0f3899c" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-31-32" >
                    <div className="elementor-widget-wrap snipcss0-5-32-33" >
                        <div className="elementor-element elementor-element-0006692 elementor-widget elementor-widget-single-icon-item snipcss0-6-33-34" data-id="0006692" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-34-35" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-36-37" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/62.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 6.2 Bulasici hastalik muhteviyatli maddeler</h4>
                                        <p className="continnerx1122212dbigsubbb">Organik peroksitler (Sınıf 5.2) O-O halinde oksijen bulunduran maddelerdir. Bunlar hidrojen peroksidin bir türevi olarak düşünülebilirler, sudaki bir yada daha fazla hidrojen atomunun organik radikallerle yer değiştirmesi yoluyla üretilirler.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
               
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  <h1 className='sdw3eq'>SINIF 7: RADYOAKTİF MADDELER</h1>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-48a181f elementor-section-boxed elementor-section-height-default snipcss0-0-0-1 snipcss-7dLnY" data-id="48a181f" data-element_type="section">
    <div className="elementor-container elementor-column-gap-default snipcss0-1-1-2" >
        <div className="elementor-row snipcss0-2-2-3" >
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54679e9 snipcss0-3-3-4" data-id="54679e9" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/7.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 7 RADYOAKTİFLER</h4>
                                        <p className="continnerx1122212dbigsubbb">Sarı RADYOAKTİF III (LSA-III) etiketini taşıyan maddeler. Bazı radyoaktif maddelerde bu etiket kullanılmasa da radyoaktiviteyi gösterir afiş bulundurmaları gerekir.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
             
                  
                    </div>
                </div>
    
            </div>
           
        
        </div>
    </div>
</section>
  <h1 className='sdw3eq'>SINIF 8: AŞINDIRICILAR</h1>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-48a181f elementor-section-boxed elementor-section-height-default snipcss0-0-0-1 snipcss-7dLnY" data-id="48a181f" data-element_type="section">
    <div className="elementor-container elementor-column-gap-default snipcss0-1-1-2" >
        <div className="elementor-row snipcss0-2-2-3" >
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54679e9 snipcss0-3-3-4" data-id="54679e9" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/8.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 8 AŞINDIRICILAR</h4>
                                        <p className="continnerx1122212dbigsubbb">Belirli bir süre temas halinde insan derisi üzerinde aşındırıcı, kalınlık azaltıcı etkisi olan maddelerdir. Çelik ve alüminyum üzerinden aşındırıcı etkisi olan maddeler de bu sınıfa girerler.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
             
                  
                    </div>
                </div>
    
            </div>
           
        
        </div>
    </div>
</section>
  <h1 className='sdw3eq'>SINIF 9: ÇEŞİTLİ TEHLİKELİ MADDELER</h1>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-48a181f elementor-section-boxed elementor-section-height-default snipcss0-0-0-1 snipcss-7dLnY" data-id="48a181f" data-element_type="section">
    <div className="elementor-container elementor-column-gap-default snipcss0-1-1-2" >
        <div className="elementor-row snipcss0-2-2-3" >
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54679e9 snipcss0-3-3-4" data-id="54679e9" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated snipcss0-4-4-5" >
                    <div className="elementor-widget-wrap snipcss0-5-5-6" >
                        <div className="elementor-element elementor-element-ec157c5 elementor-widget elementor-widget-single-icon-item snipcss0-6-6-7" data-id="ec157c5" data-element_type="widget" data-widget_type="single-icon-item.default" >
                            <div className="elementor-widget-container snipcss0-7-7-8" >
                                <div className="single-icon-box text-left continnerx1122212d" >
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/91.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="icon-box snipcss0-9-9-10" >
                                        <img decoding="async" src="https://www.globelink-unimar.com/wp-content/uploads/2021/01/9.jpg" alt="img" className="continnerx1122212dimggg"/>
                                    </div>
                                    <div className="item-box-content typography_style xsdddsaxsaddivdconter" >
                                        <h4 className="continnerx1122212dbigheader">SINIF 9 ÇEŞİTLİ
</h4>
                                        <p className="continnerx1122212dbigsubbb">Taşıma sırasında tehlike arz eden ama tanımlı sınıflardan herhangi birine uymayan maddeler bu sınıfa girer. Bu sınıfta aşağıdaki maddeler yer alır: 1. Anestezik ya da diğer tür zararlı maddeler. Bunlar uçuş ekibi ya da gemi personelinde görevlerini yerine getirmelerini engelleyecek derecede rahatsızlık yaratabilecek maddeler. 2. Sıcaklık derecesi arttırılmış maddeler, zararlı maddeler, insan sağlığına zararlı artıklar veya denizi kirletme riski olan maddeler.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
             
                  
                    </div>
                </div>
    
            </div>
           
        
        </div>
    </div>
</section>
  </div>
  <FooterFour></FooterFour>

      </div>

        </>
    )
}

export default Dangereus;
