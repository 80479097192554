import React, { useContext } from 'react';
import { FaSistrix } from "react-icons/fa";
import Layout from "../../common/Layout";
import BlogPropAstra from './itemProp/BlogPropAstral';
import SidebarPost from './sidebar/SidebarPost';
import SidebarArchive from './sidebar/SidebarArchive';
import LanguageContext from '../../LanguageContext';


const BlogGridSidebar = () => {
    const { translations } = useContext(LanguageContext);

    return (
        <>
            
            <Layout>
            
                <div className="main-content">
           
                    <div className="rn-blog-area">
                    
                        <div className="container">
                            <div className="row row--30">
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom:'30px',marginTop:'60px'}}>
                            <div>
                              <img src={process.env.PUBLIC_URL + '/images/bg/news.png'} style={{width:"180px", height:"auto"}} alt="Background"/>
                            </div>
                            <div>
                              <span  style={{fontWeight:"600", opacity:"0.8", fontSize:"60px", padding:"10px",fontFamily:"var(--font-tt-lakes-neue-trial-black)",color:"white"}}>{translations.mainnewsside}
                             </span>
                            </div>
                          </div>
                                <div className="col-lg-8">
                                    <div className="row mt_dec--30">
                                        <BlogPropAstra column="col-lg-6 col-md-6 col-12 mt--30" />
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 mt_md--40 mt_sm--40">
                                    <aside className="rwt-sidebar">

                                        <div className="rbt-single-widget widget_search mt--40">
                                            <div className="inner">
                                                <form className="blog-search" action="#">
                                                    <input type="text" placeholder={translations.mainnewssidese}/>
                                                    <button className="search-button" style={{}}><FaSistrix /></button>
                                                </form>
                                            </div>
                                        </div>


                                        <div className="rbt-single-widget widget_recent_entries mt--40">
                                            <h3 className="title">{translations.mainnewssideside}</h3>
                                            <div className="inner">
                                                <SidebarPost />
                                            </div>
                                        </div>
   
                                        <div className="rbt-single-widget widget_archive mt--40">
                                            <h3 className="title">{translations.mainnewssidesidelower}</h3>
                                            <div className="inner">
                                                <SidebarArchive />
                                            </div>
                                        </div>
       
                          

                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>

            </Layout>
        </>
    )
}

export default BlogGridSidebar
