import React, { useContext } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';

function Continerr  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
        <div className="page-wrapper" >
        <div>
        <HeaderTopBar />
        <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
      </div>
    
      <section style={{marginTop:30}} className="elementor-section elementor-top-section elementor-element elementor-element-1031104 elementor-section-boxed elementor-section-height-default snipcss-AOvoo" data-id="1031104" data-element_type="section">
    <div className="elementor-container elementor-column"  >
        <div className="elementor-row" >
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2b4c72e" data-id="2b4c72e" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated" >
                    <div className="elementor-widget-wrap" >
                        <div className="elementor-element elementor-element-a7c9cb1 elementor-widget elementor-widget-shortcode" data-id="a7c9cb1" data-element_type="widget" data-widget_type="shortcode.default" >
                            <div className="elementor-widget-container" >
                                <div className="elementor-shortcode" >
                                <h3 className="wpdt-c" id="wdt-table-title-2">{translations.contit1}</h3>
                                    <div className="wpdt-c row wpDataTableContainerSimpleTable wpDataTables wpDataTablesWrapper" >
                                        <table id="wpdtSimpleTable-2" className="wpdtSimpleTable wpDataTable style-E9dFy" data-column="3" data-rows="12" data-wpid="2" data-responsive="0" data-has-header="0">
                                            <tbody>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-aBqKA" data-cell-id="A1" data-col-index="0" data-row-index="0" id="style-aBqKA"> {translations.contit3}</td>
                                                    <td className="wpdt-cell  style-pkBtL" data-cell-id="B1" data-col-index="1" data-row-index="0" id="style-pkBtL"> 5,897 mm</td>
                                                    <td className="wpdt-cell  style-rbXSd" data-cell-id="C1" data-col-index="2" data-row-index="0" id="style-rbXSd"> 19 ft 4.17 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-gWYiM" data-cell-id="A2" data-col-index="0" data-row-index="1" id="style-gWYiM"> {translations.contit4}</td>
                                                    <td className="wpdt-cell  style-bK7aN" data-cell-id="B2" data-col-index="1" data-row-index="1" id="style-bK7aN"> 2,348 mm</td>
                                                    <td className="wpdt-cell  style-PGkSs" data-cell-id="C2" data-col-index="2" data-row-index="1" id="style-PGkSs"> 7 ft 8.44 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-pkdVl" data-cell-id="A3" data-col-index="0" data-row-index="2" id="style-pkdVl"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-JY6aA" data-cell-id="B3" data-col-index="1" data-row-index="2" id="style-JY6aA"> 2,390 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-6zVPn" data-cell-id="C3" data-col-index="2" data-row-index="2" id="style-6zVPn"> 7 ft 9.90 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC style-LZMJX" data-cell-id="A4" data-col-index="0" data-row-index="3" id="style-LZMJX"> {translations.contit6}</td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-1oOWP" data-cell-id="B4" data-col-index="1" data-row-index="3" id="style-1oOWP"></td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-qQWqm" data-cell-id="C4" data-col-index="2" data-row-index="3" id="style-qQWqm"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-UN8Mm" data-cell-id="A5" data-col-index="0" data-row-index="4" id="style-UN8Mm"> {translations.contit4}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-dJ6N1" data-cell-id="B5" data-col-index="1" data-row-index="4" id="style-dJ6N1"> 2,336 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-zGvsv" data-cell-id="C5" data-col-index="2" data-row-index="4" id="style-zGvsv"> 7 ft 8.01 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-ajH95" data-cell-id="A6" data-col-index="0" data-row-index="5" id="style-ajH95"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-eRrrl" data-cell-id="B6" data-col-index="1" data-row-index="5" id="style-eRrrl"> 2,280 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-zOlHc" data-cell-id="C6" data-col-index="2" data-row-index="5" id="style-zOlHc"> 7 ft 5.45 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CACACA style-FKbTH" data-cell-id="A7" data-col-index="0" data-row-index="6" id="style-FKbTH"> {translations.contit7}</td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-e6Wh5" data-cell-id="B7" data-col-index="1" data-row-index="6" id="style-e6Wh5"></td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-gLALx" data-cell-id="C7" data-col-index="2" data-row-index="6" id="style-gLALx"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-KszMl" data-cell-id="A8" data-col-index="0" data-row-index="7" id="style-KszMl"> {translations.contit8}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-hwwTS" data-cell-id="B8" data-col-index="1" data-row-index="7" id="style-hwwTS"> 30,480 kg (24,000 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-soyp7" data-cell-id="C8" data-col-index="2" data-row-index="7" id="style-soyp7"> 67,200 lb (52,910 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-U2w5Y" data-cell-id="A9" data-col-index="0" data-row-index="8" id="style-U2w5Y"> {translations.contit9}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-PFqGm" data-cell-id="B9" data-col-index="1" data-row-index="8" id="style-PFqGm"> 2,240 kg (2,290 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-5O2hV" data-cell-id="C9" data-col-index="2" data-row-index="8" id="style-5O2hV"> 4,940 lb (5,050 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-hh3Fz" data-cell-id="A10" data-col-index="0" data-row-index="9" id="style-hh3Fz"> {translations.contit10}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-oWUol" data-cell-id="B10" data-col-index="1" data-row-index="9" id="style-oWUol"> 28,240 kg (21,710 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-Ej8HQ" data-cell-id="C10" data-col-index="2" data-row-index="9" id="style-Ej8HQ"> 62,260 lb (47,860 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-cXTGd" data-cell-id="A11" data-col-index="0" data-row-index="10" id="style-cXTGd"> {translations.contit11}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-JBac9" data-cell-id="B11" data-col-index="1" data-row-index="10" id="style-JBac9"> 33.0 {translations.contit12}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-FC2BF" data-cell-id="C11" data-col-index="2" data-row-index="10" id="style-FC2BF"> 1,169 {translations.contit13}</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-tBF4q" data-cell-id="A12" data-col-index="0" data-row-index="11" id="style-tBF4q"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-OTqSc" data-cell-id="B12" data-col-index="1" data-row-index="11" id="style-OTqSc"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-NznXk" data-cell-id="C12" data-col-index="2" data-row-index="11" id="style-NznXk"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3bd8c06" data-id="3bd8c06" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated" >
                    <div className="elementor-widget-wrap" >
                        <div className="elementor-element elementor-element-2e093b7 elementor-widget elementor-widget-shortcode" data-id="2e093b7" data-element_type="widget" data-widget_type="shortcode.default" >
                            <div className="elementor-widget-container" >
                                <div className="elementor-shortcode" >
                                <h3 className="wpdt-c" id="wdt-table-title-3">{translations.contit2}</h3>
                                    <div className="wpdt-c row wpDataTableContainerSimpleTable wpDataTables wpDataTablesWrapper" >
                                        <table id="wpdtSimpleTable-3" className="wpdtSimpleTable wpDataTable style-opmh2" data-column="3" data-rows="12" data-wpid="3" data-responsive="0" data-has-header="0">
                                            <tbody>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-aEVkD" data-cell-id="A1" data-col-index="0" data-row-index="0" id="style-aEVkD"> {translations.contit3}</td>
                                                    <td className="wpdt-cell  style-wY89y" data-cell-id="B1" data-col-index="1" data-row-index="0" id="style-wY89y"> 12,031 mm</td>
                                                    <td className="wpdt-cell  style-dZCY9" data-cell-id="C1" data-col-index="2" data-row-index="0" id="style-dZCY9"> 39 ft 5.66 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-3o1XN" data-cell-id="A2" data-col-index="0" data-row-index="1" id="style-3o1XN"> {translations.contit4}</td>
                                                    <td className="wpdt-cell  style-WMsCr" data-cell-id="B2" data-col-index="1" data-row-index="1" id="style-WMsCr"> 2,348 mm</td>
                                                    <td className="wpdt-cell  style-FZfwd" data-cell-id="C2" data-col-index="2" data-row-index="1" id="style-FZfwd"> 7 ft 8.44 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-hah7b" data-cell-id="A3" data-col-index="0" data-row-index="2" id="style-hah7b"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-PY9qx" data-cell-id="B3" data-col-index="1" data-row-index="2" id="style-PY9qx"> 2,695 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-g2Rw7" data-cell-id="C3" data-col-index="2" data-row-index="2" id="style-g2Rw7"> 8 ft 10.12 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC style-CvOr6" data-cell-id="A4" data-col-index="0" data-row-index="3" id="style-CvOr6"> {translations.contit6}</td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-wH233" data-cell-id="B4" data-col-index="1" data-row-index="3" id="style-wH233"></td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-O21iB" data-cell-id="C4" data-col-index="2" data-row-index="3" id="style-O21iB"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-GV2tS" data-cell-id="A5" data-col-index="0" data-row-index="4" id="style-GV2tS"> {translations.contit4}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-Y7EL5" data-cell-id="B5" data-col-index="1" data-row-index="4" id="style-Y7EL5"> 2,336 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-TIsVI" data-cell-id="C5" data-col-index="2" data-row-index="4" id="style-TIsVI"> 7 ft 8.01 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-Us1tX" data-cell-id="A6" data-col-index="0" data-row-index="5" id="style-Us1tX"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-9lMvo" data-cell-id="B6" data-col-index="1" data-row-index="5" id="style-9lMvo"> 2,585 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-VsjVf" data-cell-id="C6" data-col-index="2" data-row-index="5" id="style-VsjVf"> 8 ft 5.75 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CACACA style-Q1e4r" data-cell-id="A7" data-col-index="0" data-row-index="6" id="style-Q1e4r"> {translations.contit7}</td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-x2KAL" data-cell-id="B7" data-col-index="1" data-row-index="6" id="style-x2KAL"></td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-EMP2o" data-cell-id="C7" data-col-index="2" data-row-index="6" id="style-EMP2o"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-a1HAy" data-cell-id="A8" data-col-index="0" data-row-index="7" id="style-a1HAy"> {translations.contit8}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-cTkIo" data-cell-id="B8" data-col-index="1" data-row-index="7" id="style-cTkIo"> 32,500 kg (30,480 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-YIYdd" data-cell-id="C8" data-col-index="2" data-row-index="7" id="style-YIYdd"> 71,650 lb (67,200 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-VhHxG" data-cell-id="A9" data-col-index="0" data-row-index="8" id="style-VhHxG"> {translations.contit9}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-3qkVg" data-cell-id="B9" data-col-index="1" data-row-index="8" id="style-3qkVg"> 3,940 kg (3,990 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-n87KS" data-cell-id="C9" data-col-index="2" data-row-index="8" id="style-n87KS"> 8,690 lb (8,880 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-SXNxF" data-cell-id="A10" data-col-index="0" data-row-index="9" id="style-SXNxF"> {translations.contit10}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-rvZ9y" data-cell-id="B10" data-col-index="1" data-row-index="9" id="style-rvZ9y"> 28,560 kg (26,490 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-SOV5h" data-cell-id="C10" data-col-index="2" data-row-index="9" id="style-SOV5h"> 62,960 lb (58,400 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-oXgBl" data-cell-id="A11" data-col-index="0" data-row-index="10" id="style-oXgBl"> {translations.contit11}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-pMwcU" data-cell-id="B11" data-col-index="1" data-row-index="10" id="style-pMwcU"> 76.0 {translations.contit12}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-7LQCr" data-cell-id="C11" data-col-index="2" data-row-index="10" id="style-7LQCr"> 2,689 {translations.contit13}(2,684)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-YSshU" data-cell-id="A12" data-col-index="0" data-row-index="11" id="style-YSshU"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-Sq9dq" data-cell-id="B12" data-col-index="1" data-row-index="11" id="style-Sq9dq"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-ZsrEe" data-cell-id="C12" data-col-index="2" data-row-index="11" id="style-ZsrEe"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="elementor-section elementor-top-section elementor-element elementor-element-40ef851 elementor-section-boxed elementor-section-height-default snipcss-e1yf9" data-id="40ef851" data-element_type="section">
    <div className="elementor-container elementor-column" >
        <div className="elementor-row" >
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-99295cc" data-id="99295cc" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated" >
                    <div className="elementor-widget-wrap" >
                        <div className="elementor-element elementor-element-0cae43a elementor-widget elementor-widget-shortcode" data-id="0cae43a" data-element_type="widget" data-widget_type="shortcode.default" >
                            <div className="elementor-widget-container" >
                                <div className="elementor-shortcode" >
                                    <h3 className="wpdt-c" id="wdt-table-title-4">{translations.contittit2}</h3>
                                    <div className="wpdt-c row wpDataTableContainerSimpleTable wpDataTables wpDataTablesWrapper" >
                                        <table id="wpdtSimpleTable-4" className="wpdtSimpleTable wpDataTable style-ydrBU" data-column="3" data-rows="12" data-wpid="4" data-responsive="0" data-has-header="0">
                                            <tbody>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-IzEV9" data-cell-id="A1" data-col-index="0" data-row-index="0" id="style-IzEV9"> {translations.contit3}</td>
                                                    <td className="wpdt-cell  style-zDLMw" data-cell-id="B1" data-col-index="1" data-row-index="0" id="style-zDLMw"> 5,893 mm</td>
                                                    <td className="wpdt-cell  style-hJcdY" data-cell-id="C1" data-col-index="2" data-row-index="0" id="style-hJcdY"> 19 ft 4.21in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-MRUn6" data-cell-id="A2" data-col-index="0" data-row-index="1" id="style-MRUn6"> {translations.contit4}</td>
                                                    <td className="wpdt-cell  style-6mjgo" data-cell-id="B2" data-col-index="1" data-row-index="1" id="style-6mjgo"> 2,346 mm</td>
                                                    <td className="wpdt-cell  style-F1S74" data-cell-id="C2" data-col-index="2" data-row-index="1" id="style-F1S74"> 7 ft 8.36 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-8n5HE" data-cell-id="A3" data-col-index="0" data-row-index="2" id="style-8n5HE"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-Shqg7" data-cell-id="B3" data-col-index="1" data-row-index="2" id="style-Shqg7"> 2,384 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-XlkCy" data-cell-id="C3" data-col-index="2" data-row-index="2" id="style-XlkCy"> 7 ft 9.74 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC style-hoqZM" data-cell-id="A4" data-col-index="0" data-row-index="3" id="style-hoqZM"> {translations.contit6}</td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-OXsQ4" data-cell-id="B4" data-col-index="1" data-row-index="3" id="style-OXsQ4"></td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-JLvRU" data-cell-id="C4" data-col-index="2" data-row-index="3" id="style-JLvRU"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-1Vt6G" data-cell-id="A5" data-col-index="0" data-row-index="4" id="style-1Vt6G"> {translations.contit4}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-1LB2e" data-cell-id="B5" data-col-index="1" data-row-index="4" id="style-1LB2e"> 2,338 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-TsyUc" data-cell-id="C5" data-col-index="2" data-row-index="4" id="style-TsyUc"> 7 ft 8.05 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-bK3eJ" data-cell-id="A6" data-col-index="0" data-row-index="5" id="style-bK3eJ"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-UGwh9" data-cell-id="B6" data-col-index="1" data-row-index="5" id="style-UGwh9"> 2,244 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-GZq79" data-cell-id="C6" data-col-index="2" data-row-index="5" id="style-GZq79"> 7 ft 4.35 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CACACA style-pBZLl" data-cell-id="A7" data-col-index="0" data-row-index="6" id="style-pBZLl"> {translations.contit7}</td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-Uix8T" data-cell-id="B7" data-col-index="1" data-row-index="6" id="style-Uix8T"></td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-7Jr8J" data-cell-id="C7" data-col-index="2" data-row-index="6" id="style-7Jr8J"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-qWbUI" data-cell-id="A8" data-col-index="0" data-row-index="7" id="style-qWbUI"> {translations.contit8}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-yGHeO" data-cell-id="B8" data-col-index="1" data-row-index="7" id="style-yGHeO"> 30,480 kg (24,000 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-kPOsf" data-cell-id="C8" data-col-index="2" data-row-index="7" id="style-kPOsf"> 67,200 lb (52,910 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-YiewY" data-cell-id="A9" data-col-index="0" data-row-index="8" id="style-YiewY"> {translations.contit9}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-n7SOn" data-cell-id="B9" data-col-index="1" data-row-index="8" id="style-n7SOn"> 2,320 kg (2,450 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-e1bLl" data-cell-id="C9" data-col-index="2" data-row-index="8" id="style-e1bLl"> 5,120 lb (5,400 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-k9qXr" data-cell-id="A10" data-col-index="0" data-row-index="9" id="style-k9qXr"> {translations.contit10}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-o6KId" data-cell-id="B10" data-col-index="1" data-row-index="9" id="style-o6KId"> 28,160 kg (21,550 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-1bp1H" data-cell-id="C10" data-col-index="2" data-row-index="9" id="style-1bp1H"> 62,080 lb (47,510 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-o9P6A" data-cell-id="A11" data-col-index="0" data-row-index="10" id="style-o9P6A"> {translations.contit11}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-K5bAC" data-cell-id="B11" data-col-index="1" data-row-index="10" id="style-K5bAC"> 33 {translations.contit12}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-eowaW" data-cell-id="C11" data-col-index="2" data-row-index="10" id="style-eowaW"> 1,155 {translations.contit13}</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-Sdrww" data-cell-id="A12" data-col-index="0" data-row-index="11" id="style-Sdrww"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-CN4tg" data-cell-id="B12" data-col-index="1" data-row-index="11" id="style-CN4tg"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-hpodb" data-cell-id="C12" data-col-index="2" data-row-index="11" id="style-hpodb"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6f513df" data-id="6f513df" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated" >
                    <div className="elementor-widget-wrap" >
                        <div className="elementor-element elementor-element-737f582 elementor-widget elementor-widget-shortcode" data-id="737f582" data-element_type="widget" data-widget_type="shortcode.default" >
                            <div className="elementor-widget-container" >
                                <div className="elementor-shortcode" >
                                    <h3 className="wpdt-c" id="wdt-table-title-5">{translations.contittit3}</h3>
                                    <div className="wpdt-c row wpDataTableContainerSimpleTable wpDataTables wpDataTablesWrapper
" >
                                        <table id="wpdtSimpleTable-5" className="wpdtSimpleTable wpDataTable style-yoKs6" data-column="3" data-rows="12" data-wpid="5" data-responsive="0" data-has-header="0">
                                            <tbody>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-8BdNb" data-cell-id="A1" data-col-index="0" data-row-index="0" id="style-8BdNb"> {translations.contit3}</td>
                                                    <td className="wpdt-cell  style-pahoj" data-cell-id="B1" data-col-index="1" data-row-index="0" id="style-pahoj"> 5,458 mm</td>
                                                    <td className="wpdt-cell  style-oOIyM" data-cell-id="C1" data-col-index="2" data-row-index="0" id="style-oOIyM"> 17 ft 10.76 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-yQTzR" data-cell-id="A2" data-col-index="0" data-row-index="1" id="style-yQTzR"> {translations.contit4}</td>
                                                    <td className="wpdt-cell  style-14CTz" data-cell-id="B2" data-col-index="1" data-row-index="1" id="style-14CTz"> 2,290 mm</td>
                                                    <td className="wpdt-cell  style-JXFoP" data-cell-id="C2" data-col-index="2" data-row-index="1" id="style-JXFoP"> 7 ft 6.16 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-PFIpE" data-cell-id="A3" data-col-index="0" data-row-index="2" id="style-PFIpE"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-aQoIg" data-cell-id="B3" data-col-index="1" data-row-index="2" id="style-aQoIg"> 2,262 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-7UA91" data-cell-id="C3" data-col-index="2" data-row-index="2" id="style-7UA91"> 7 ft 5.06 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC style-hlKwY" data-cell-id="A4" data-col-index="0" data-row-index="3" id="style-hlKwY"> {translations.contit6}</td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-LYZrH" data-cell-id="B4" data-col-index="1" data-row-index="3" id="style-LYZrH"></td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-cLOtJ" data-cell-id="C4" data-col-index="2" data-row-index="3" id="style-cLOtJ"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-SIP9T" data-cell-id="A5" data-col-index="0" data-row-index="4" id="style-SIP9T"> {translations.contit4}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-tCoSl" data-cell-id="B5" data-col-index="1" data-row-index="4" id="style-tCoSl"> 2,290 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-rG5cX" data-cell-id="C5" data-col-index="2" data-row-index="4" id="style-rG5cX"> 7 ft 6.16 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-6GvOh" data-cell-id="A6" data-col-index="0" data-row-index="5" id="style-6GvOh"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-syqDQ" data-cell-id="B6" data-col-index="1" data-row-index="5" id="style-syqDQ"> 2,267 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-2HbiB" data-cell-id="C6" data-col-index="2" data-row-index="5" id="style-2HbiB"> 7 ft 5.10 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CACACA style-1lxha" data-cell-id="A7" data-col-index="0" data-row-index="6" id="style-1lxha"> {translations.contit7}</td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-t9H8N" data-cell-id="B7" data-col-index="1" data-row-index="6" id="style-t9H8N"></td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-ZTBdy" data-cell-id="C7" data-col-index="2" data-row-index="6" id="style-ZTBdy"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-bECtg" data-cell-id="A8" data-col-index="0" data-row-index="7" id="style-bECtg"> {translations.contit8}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-dqy3d" data-cell-id="B8" data-col-index="1" data-row-index="7" id="style-dqy3d"> 30,480 kg (24,000 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-rCeEQ" data-cell-id="C8" data-col-index="2" data-row-index="7" id="style-rCeEQ"> 67,200 lb (52,910 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-XaN9o" data-cell-id="A9" data-col-index="0" data-row-index="8" id="style-XaN9o"> {translations.contit9}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-wGa5G" data-cell-id="B9" data-col-index="1" data-row-index="8" id="style-wGa5G"> 3,120 kg (2,960 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-Q8c1w" data-cell-id="C9" data-col-index="2" data-row-index="8" id="style-Q8c1w"> 6,880 lb (6,530 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-WvFSW" data-cell-id="A10" data-col-index="0" data-row-index="9" id="style-WvFSW"> {translations.contit10}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-oUrAi" data-cell-id="B10" data-col-index="1" data-row-index="9" id="style-oUrAi"> 27,360 kg (21,040 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-RSmSN" data-cell-id="C10" data-col-index="2" data-row-index="9" id="style-RSmSN"> 60,320 lb (46,380 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-mN9ts" data-cell-id="A11" data-col-index="0" data-row-index="10" id="style-mN9ts"> {translations.contit11}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-eFGld" data-cell-id="B11" data-col-index="1" data-row-index="10" id="style-eFGld"> 28.0 {translations.contit12}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-whPK5" data-cell-id="C11" data-col-index="2" data-row-index="10" id="style-whPK5"> 1,000 {translations.contit13}</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-NoY26" data-cell-id="A12" data-col-index="0" data-row-index="11" id="style-NoY26"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-ADm3P" data-cell-id="B12" data-col-index="1" data-row-index="11" id="style-ADm3P"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-5K1i7" data-cell-id="C12" data-col-index="2" data-row-index="11" id="style-5K1i7"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="elementor-section elementor-top-section elementor-element elementor-element-57de799 elementor-section-boxed elementor-section-height-default snipcss-UivYF" data-id="57de799" data-element_type="section">
    <div className="elementor-container elementor-column" >
        <div className="elementor-row" >
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-24dba11" data-id="24dba11" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated" >
                    <div className="elementor-widget-wrap" >
                        <div className="elementor-element elementor-element-a51095a elementor-widget elementor-widget-shortcode" data-id="a51095a" data-element_type="widget" data-widget_type="shortcode.default" >
                            <div className="elementor-widget-container" >
                                <div className="elementor-shortcode" >
                                    <h3 className="wpdt-c" id="wdt-table-title-6">{translations.contittit4}</h3>
                                    <div className="wpdt-c row wpDataTableContainerSimpleTable wpDataTables wpDataTablesWrapper" >
                                        <table id="wpdtSimpleTable-6" className="wpdtSimpleTable wpDataTable style-PlywT" data-column="3" data-rows="8" data-wpid="6" data-responsive="0" data-has-header="0">
                                            <tbody>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-tc-000000 wpdt-bc-D1D1D1 style-SY15X" data-cell-id="A1" data-col-index="0" data-row-index="0" id="style-SY15X"> {translations.contaddi}</td>
                                                    <td className="wpdt-cell wpdt-bc-D1D1D1 wpdt-tc-DBDBDB wpdt-empty-cell  style-Fccnj" data-cell-id="B1" data-col-index="1" data-row-index="0" id="style-Fccnj"></td>
                                                    <td className="wpdt-cell wpdt-bc-D1D1D1 wpdt-tc-DBDBDB wpdt-empty-cell  style-eoYAO" data-cell-id="C1" data-col-index="2" data-row-index="0" id="style-eoYAO"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-5r4vi" data-cell-id="A2" data-col-index="0" data-row-index="1" id="style-5r4vi"> {translations.contit3}</td>
                                                    <td className="wpdt-cell  style-xXjpP" data-cell-id="B2" data-col-index="1" data-row-index="1" id="style-xXjpP"> 5,958 mm</td>
                                                    <td className="wpdt-cell  style-48j1f" data-cell-id="C2" data-col-index="2" data-row-index="1" id="style-48j1f"> 19 ft 4.17 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-J3WYt" data-cell-id="A3" data-col-index="0" data-row-index="2" id="style-J3WYt"> {translations.contit4}</td>
                                                    <td className="wpdt-cell  style-OXXkT" data-cell-id="B3" data-col-index="1" data-row-index="2" id="style-OXXkT"> 2,018 mm</td>
                                                    <td className="wpdt-cell  style-zCoil" data-cell-id="C3" data-col-index="2" data-row-index="2" id="style-zCoil"> 6 ft 7.45 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-odbzQ" data-cell-id="A4" data-col-index="0" data-row-index="3" id="style-odbzQ"> {translations.contit5}</td>
                                                    <td className="wpdt-cell  style-Cmf7q" data-cell-id="B4" data-col-index="1" data-row-index="3" id="style-Cmf7q"> 2,077 mm</td>
                                                    <td className="wpdt-cell  style-A6oWS" data-cell-id="C4" data-col-index="2" data-row-index="3" id="style-A6oWS"> 6 ft 9.77 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-oooCf" data-cell-id="A5" data-col-index="0" data-row-index="4" id="style-oooCf"> {translations.contit8}</td>
                                                    <td className="wpdt-cell  style-FmsWI" data-cell-id="B5" data-col-index="1" data-row-index="4" id="style-FmsWI"> 30,480 kg</td>
                                                    <td className="wpdt-cell  style-gpr6K" data-cell-id="C5" data-col-index="2" data-row-index="4" id="style-gpr6K"> 67,200 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-pgjrF" data-cell-id="A6" data-col-index="0" data-row-index="5" id="style-pgjrF"> {translations.contit9}</td>
                                                    <td className="wpdt-cell  style-PqovF" data-cell-id="B6" data-col-index="1" data-row-index="5" id="style-PqovF"> 2,720 kg</td>
                                                    <td className="wpdt-cell  style-w7BMg" data-cell-id="C6" data-col-index="2" data-row-index="5" id="style-w7BMg"> 6,000 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-4HVDz" data-cell-id="A7" data-col-index="0" data-row-index="6" id="style-4HVDz"> {translations.contit10}</td>
                                                    <td className="wpdt-cell  style-5gVfc" data-cell-id="B7" data-col-index="1" data-row-index="6" id="style-5gVfc"> 27,760 kg</td>
                                                    <td className="wpdt-cell  style-U4Kom" data-cell-id="C7" data-col-index="2" data-row-index="6" id="style-U4Kom"> 62,120 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-MIOjX" data-cell-id="A8" data-col-index="0" data-row-index="7" id="style-MIOjX"> {translations.contit11}</td>
                                                    <td className="wpdt-cell  style-NgQ8K" data-cell-id="B8" data-col-index="1" data-row-index="7" id="style-NgQ8K"> 25 {translations.contit12}</td>
                                                    <td className="wpdt-cell  style-tOVFm" data-cell-id="C8" data-col-index="2" data-row-index="7" id="style-tOVFm"> 882 {translations.contit13}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-020d8f0" data-id="020d8f0" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated" >
                    <div className="elementor-widget-wrap" >
                        <div className="elementor-element elementor-element-9831be7 elementor-widget elementor-widget-shortcode" data-id="9831be7" data-element_type="widget" data-widget_type="shortcode.default" >
                            <div className="elementor-widget-container" >
                                <div className="elementor-shortcode" >
                                    <h3 className="wpdt-c" id="wdt-table-title-7">{translations.contittit5}</h3>
                                    <div className="wpdt-c row wpDataTableContainerSimpleTable wpDataTables wpDataTablesWrapper" >
                                        <table id="wpdtSimpleTable-7" className="wpdtSimpleTable wpDataTable style-pP6IF" data-column="3" data-rows="12" data-wpid="7" data-responsive="0" data-has-header="0">
                                            <tbody>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-NMMdb" data-cell-id="A1" data-col-index="0" data-row-index="0" id="style-NMMdb"> {translations.contit3}</td>
                                                    <td className="wpdt-cell  style-nMLwh" data-cell-id="B1" data-col-index="1" data-row-index="0" id="style-nMLwh"> 12,031 mm</td>
                                                    <td className="wpdt-cell  style-o3EsM" data-cell-id="C1" data-col-index="2" data-row-index="0" id="style-o3EsM"> 19 ft 4.17 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-iG6R4" data-cell-id="A2" data-col-index="0" data-row-index="1" id="style-iG6R4"> {translations.contit4}</td>
                                                    <td className="wpdt-cell  style-RfZJA" data-cell-id="B2" data-col-index="1" data-row-index="1" id="style-RfZJA"> 2,348 mm</td>
                                                    <td className="wpdt-cell  style-UoeEn" data-cell-id="C2" data-col-index="2" data-row-index="1" id="style-UoeEn"> 7 ft 8.44 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-bKp13" data-cell-id="A3" data-col-index="0" data-row-index="2" id="style-bKp13"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-t5MgW" data-cell-id="B3" data-col-index="1" data-row-index="2" id="style-t5MgW"> 2,390 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-oeLb4" data-cell-id="C3" data-col-index="2" data-row-index="2" id="style-oeLb4"> 7 ft 9.90 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC style-dam2o" data-cell-id="A4" data-col-index="0" data-row-index="3" id="style-dam2o"> {translations.contit6}</td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-ptnDD" data-cell-id="B4" data-col-index="1" data-row-index="3" id="style-ptnDD"></td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-kZ1Wn" data-cell-id="C4" data-col-index="2" data-row-index="3" id="style-kZ1Wn"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-qSsDP" data-cell-id="A5" data-col-index="0" data-row-index="4" id="style-qSsDP"> {translations.contit4}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-UTnRL" data-cell-id="B5" data-col-index="1" data-row-index="4" id="style-UTnRL"> 2,336 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-WiBxq" data-cell-id="C5" data-col-index="2" data-row-index="4" id="style-WiBxq"> 7 ft 8.01 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-KlBt2" data-cell-id="A6" data-col-index="0" data-row-index="5" id="style-KlBt2"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-QVgOR" data-cell-id="B6" data-col-index="1" data-row-index="5" id="style-QVgOR"> 2,280 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-ACjh9" data-cell-id="C6" data-col-index="2" data-row-index="5" id="style-ACjh9"> 7 ft 5.45 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CACACA style-rZDNY" data-cell-id="A7" data-col-index="0" data-row-index="6" id="style-rZDNY"> {translations.contit7}</td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-stZoT" data-cell-id="B7" data-col-index="1" data-row-index="6" id="style-stZoT"></td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-XDBWr" data-cell-id="C7" data-col-index="2" data-row-index="6" id="style-XDBWr"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-Orzxq" data-cell-id="A8" data-col-index="0" data-row-index="7" id="style-Orzxq"> {translations.contit8}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-dMJCa" data-cell-id="B8" data-col-index="1" data-row-index="7" id="style-dMJCa"> 32,500 kg (30,480 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-rF6nW" data-cell-id="C8" data-col-index="2" data-row-index="7" id="style-rF6nW"> 71,650 lb (67,200 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-DYlfE" data-cell-id="A9" data-col-index="0" data-row-index="8" id="style-DYlfE"> {translations.contit9}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-GLoon" data-cell-id="B9" data-col-index="1" data-row-index="8" id="style-GLoon"> 3,750 kg (3,770 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-72s1L" data-cell-id="C9" data-col-index="2" data-row-index="8" id="style-72s1L"> 8,270 lb (8,310 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-MSkPR" data-cell-id="A10" data-col-index="0" data-row-index="9" id="style-MSkPR"> {translations.contit10}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-wcGhB" data-cell-id="B10" data-col-index="1" data-row-index="9" id="style-wcGhB"> 28,750 kg (26,710 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-pi5SN" data-cell-id="C10" data-col-index="2" data-row-index="9" id="style-pi5SN"> 63,380 lb (58,890 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-PTJsh" data-cell-id="A11" data-col-index="0" data-row-index="10" id="style-PTJsh"> {translations.contit11}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-iwgtD" data-cell-id="B11" data-col-index="1" data-row-index="10" id="style-iwgtD"> 68.0 {translations.contit12}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-qe7xV" data-cell-id="C11" data-col-index="2" data-row-index="10" id="style-qe7xV"> 2,385 {translations.contit13}(2,379)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-vWdpR" data-cell-id="A12" data-col-index="0" data-row-index="11" id="style-vWdpR"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-ZoOWs" data-cell-id="B12" data-col-index="1" data-row-index="11" id="style-ZoOWs"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-mAVph" data-cell-id="C12" data-col-index="2" data-row-index="11" id="style-mAVph"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="elementor-section elementor-top-section elementor-element elementor-element-098bca7 elementor-section-boxed elementor-section-height-default snipcss-d6e6r" data-id="098bca7" data-element_type="section">
    <div className="elementor-container elementor-column" >
        <div className="elementor-row" >
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f63ea7a" data-id="f63ea7a" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated" >
                    <div className="elementor-widget-wrap" >
                        <div className="elementor-element elementor-element-cdd3a66 elementor-widget elementor-widget-shortcode" data-id="cdd3a66" data-element_type="widget" data-widget_type="shortcode.default" >
                            <div className="elementor-widget-container" >
                                <div className="elementor-shortcode" >
                                    <h3 className="wpdt-c" id="wdt-table-title-8">{translations.contittit6}</h3>
                                    <div className="wpdt-c row wpDataTableContainerSimpleTable wpDataTables wpDataTablesWrapper" >
                                        <table id="wpdtSimpleTable-8" className="wpdtSimpleTable wpDataTable style-clhC6" data-column="3" data-rows="12" data-wpid="8" data-responsive="0" data-has-header="0">
                                            <tbody>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-FSZr5" data-cell-id="A1" data-col-index="0" data-row-index="0" id="style-FSZr5"> {translations.contit3}</td>
                                                    <td className="wpdt-cell  style-KofNp" data-cell-id="B1" data-col-index="1" data-row-index="0" id="style-KofNp"> 12,032 mm</td>
                                                    <td className="wpdt-cell  style-mo5W7" data-cell-id="C1" data-col-index="2" data-row-index="0" id="style-mo5W7"> 39 ft 5.31in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-bBPMQ" data-cell-id="A2" data-col-index="0" data-row-index="1" id="style-bBPMQ"> {translations.contit4}</td>
                                                    <td className="wpdt-cell  style-yAsrn" data-cell-id="B2" data-col-index="1" data-row-index="1" id="style-yAsrn"> 2,346 mm</td>
                                                    <td className="wpdt-cell  style-21RY5" data-cell-id="C2" data-col-index="2" data-row-index="1" id="style-21RY5"> 7 ft 8.36 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-ozj6t" data-cell-id="A3" data-col-index="0" data-row-index="2" id="style-ozj6t"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-UZEBW" data-cell-id="B3" data-col-index="1" data-row-index="2" id="style-UZEBW"> 2,381 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-2opkf" data-cell-id="C3" data-col-index="2" data-row-index="2" id="style-2opkf"> 7 ft 9.74 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC style-ApeP2" data-cell-id="A4" data-col-index="0" data-row-index="3" id="style-ApeP2"> {translations.contit6}</td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-t7O6M" data-cell-id="B4" data-col-index="1" data-row-index="3" id="style-t7O6M"></td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-WXsf1" data-cell-id="C4" data-col-index="2" data-row-index="3" id="style-WXsf1"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-7OQso" data-cell-id="A5" data-col-index="0" data-row-index="4" id="style-7OQso"> {translations.contit4}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-8oEL5" data-cell-id="B5" data-col-index="1" data-row-index="4" id="style-8oEL5"> 2,338 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-DMcHy" data-cell-id="C5" data-col-index="2" data-row-index="4" id="style-DMcHy"> 7 ft 8.05 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-cagke" data-cell-id="A6" data-col-index="0" data-row-index="5" id="style-cagke"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-HAEM1" data-cell-id="B6" data-col-index="1" data-row-index="5" id="style-HAEM1"> 2,244 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-tsINf" data-cell-id="C6" data-col-index="2" data-row-index="5" id="style-tsINf"> 7 ft 4.35 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CACACA style-bTJ7k" data-cell-id="A7" data-col-index="0" data-row-index="6" id="style-bTJ7k"> {translations.contit7}</td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-7FW1p" data-cell-id="B7" data-col-index="1" data-row-index="6" id="style-7FW1p"></td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-MkFlE" data-cell-id="C7" data-col-index="2" data-row-index="6" id="style-MkFlE"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-2dM5V" data-cell-id="A8" data-col-index="0" data-row-index="7" id="style-2dM5V"> {translations.contit8}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-pbsh9" data-cell-id="B8" data-col-index="1" data-row-index="7" id="style-pbsh9"> 30,480 kg</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-J4BbU" data-cell-id="C8" data-col-index="2" data-row-index="7" id="style-J4BbU"> 67,200 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-ZXGKP" data-cell-id="A9" data-col-index="0" data-row-index="8" id="style-ZXGKP"> {translations.contit9}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-of5H6" data-cell-id="B9" data-col-index="1" data-row-index="8" id="style-of5H6"> 4,120 kg</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-yAcFb" data-cell-id="C9" data-col-index="2" data-row-index="8" id="style-yAcFb"> 9,080 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-m76Ui" data-cell-id="A10" data-col-index="0" data-row-index="9" id="style-m76Ui"> {translations.contit10}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-lXvM5" data-cell-id="B10" data-col-index="1" data-row-index="9" id="style-lXvM5"> 26,360 kg</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-KmgJH" data-cell-id="C10" data-col-index="2" data-row-index="9" id="style-KmgJH"> 58,120 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-ztWi1" data-cell-id="A11" data-col-index="0" data-row-index="10" id="style-ztWi1"> {translations.contit11}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-8PEdJ" data-cell-id="B11" data-col-index="1" data-row-index="10" id="style-8PEdJ"> 66 {translations.contit12}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-Rx1Fs" data-cell-id="C11" data-col-index="2" data-row-index="10" id="style-Rx1Fs"> 2,346 {translations.contit13}</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-DlTgd" data-cell-id="A12" data-col-index="0" data-row-index="11" id="style-DlTgd"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-45226" data-cell-id="B12" data-col-index="1" data-row-index="11" id="style-45226"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-oXTts" data-cell-id="C12" data-col-index="2" data-row-index="11" id="style-oXTts"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9a5ea74" data-id="9a5ea74" data-element_type="column" >
                <div className="elementor-column-wrap elementor-element-populated" >
                    <div className="elementor-widget-wrap" >
                        <div className="elementor-element elementor-element-3a6600d elementor-widget elementor-widget-shortcode" data-id="3a6600d" data-element_type="widget" data-widget_type="shortcode.default" >
                            <div className="elementor-widget-container" >
                                <div className="elementor-shortcode" >
                                    <h3 className="wpdt-c" id="wdt-table-title-9">{translations.contittit7}</h3>
                                    <div className="wpdt-c row wpDataTableContainerSimpleTable wpDataTables wpDataTablesWrapper
" >
                                        <table id="wpdtSimpleTable-9" className="wpdtSimpleTable wpDataTable style-kKtoz" data-column="3" data-rows="12" data-wpid="9" data-responsive="0" data-has-header="0">
                                            <tbody>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-E7XMi" data-cell-id="A1" data-col-index="0" data-row-index="0" id="style-E7XMi"> {translations.contit3}</td>
                                                    <td className="wpdt-cell  style-OcfEy" data-cell-id="B1" data-col-index="1" data-row-index="0" id="style-OcfEy"> 11,587 mm</td>
                                                    <td className="wpdt-cell  style-VD4cF" data-cell-id="C1" data-col-index="2" data-row-index="0" id="style-VD4cF"> 38 ft 0.29 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-sHdoX" data-cell-id="A2" data-col-index="0" data-row-index="1" id="style-sHdoX"> {translations.contit4}</td>
                                                    <td className="wpdt-cell  style-fp4F3" data-cell-id="B2" data-col-index="1" data-row-index="1" id="style-fp4F3"> 2,290 mm</td>
                                                    <td className="wpdt-cell  style-iqgo2" data-cell-id="C2" data-col-index="2" data-row-index="1" id="style-iqgo2"> 7 ft 6.16 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-WgHq1" data-cell-id="A3" data-col-index="0" data-row-index="2" id="style-WgHq1"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-rKd1o" data-cell-id="B3" data-col-index="1" data-row-index="2" id="style-rKd1o"> 2,539 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-nbxBi" data-cell-id="C3" data-col-index="2" data-row-index="2" id="style-nbxBi"> 8 ft 4.00 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC style-TJJCq" data-cell-id="A4" data-col-index="0" data-row-index="3" id="style-TJJCq"> {translations.contit6}</td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-XUzUZ" data-cell-id="B4" data-col-index="1" data-row-index="3" id="style-XUzUZ"></td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-TtyKY" data-cell-id="C4" data-col-index="2" data-row-index="3" id="style-TtyKY"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-ke6G8" data-cell-id="A5" data-col-index="0" data-row-index="4" id="style-ke6G8"> {translations.contit4}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-SSFr9" data-cell-id="B5" data-col-index="1" data-row-index="4" id="style-SSFr9"> 2,290 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-oUnoR" data-cell-id="C5" data-col-index="2" data-row-index="4" id="style-oUnoR"> 7 ft 6.16 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-TNBZx" data-cell-id="A6" data-col-index="0" data-row-index="5" id="style-TNBZx"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-iVZJg" data-cell-id="B6" data-col-index="1" data-row-index="5" id="style-iVZJg"> 2,572 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-NamUk" data-cell-id="C6" data-col-index="2" data-row-index="5" id="style-NamUk"> 8 ft 5.40 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CACACA style-bh3tZ" data-cell-id="A7" data-col-index="0" data-row-index="6" id="style-bh3tZ"> {translations.contit7}</td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-CUT6G" data-cell-id="B7" data-col-index="1" data-row-index="6" id="style-CUT6G"></td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-kNrnx" data-cell-id="C7" data-col-index="2" data-row-index="6" id="style-kNrnx"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-qRni9" data-cell-id="A8" data-col-index="0" data-row-index="7" id="style-qRni9"> {translations.contit8}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-fHvbo" data-cell-id="B8" data-col-index="1" data-row-index="7" id="style-fHvbo"> 34,000 kg (30,480 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-IHzXE" data-cell-id="C8" data-col-index="2" data-row-index="7" id="style-IHzXE"> 74,960 lb (67,200 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-zkdsk" data-cell-id="A9" data-col-index="0" data-row-index="8" id="style-zkdsk"> {translations.contit9}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-WtbmI" data-cell-id="B9" data-col-index="1" data-row-index="8" id="style-WtbmI"> 4,700 kg (4,800 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-EmmMw" data-cell-id="C9" data-col-index="2" data-row-index="8" id="style-EmmMw"> 10,360 lb (10,580 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-8fFVB" data-cell-id="A10" data-col-index="0" data-row-index="9" id="style-8fFVB"> {translations.contit10}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-i7yhz" data-cell-id="B10" data-col-index="1" data-row-index="9" id="style-i7yhz"> 29,300 kg (25,680 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-haPaw" data-cell-id="C10" data-col-index="2" data-row-index="9" id="style-haPaw"> 74,960 lb (56,620 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-76rJL" data-cell-id="A11" data-col-index="0" data-row-index="10" id="style-76rJL"> {translations.contit11}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-ABqvD" data-cell-id="B11" data-col-index="1" data-row-index="10" id="style-ABqvD"> 67.0 {translations.contit12}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-delRF" data-cell-id="C11" data-col-index="2" data-row-index="10" id="style-delRF"> 2,378 {translations.contit13}</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-Gm54t" data-cell-id="A12" data-col-index="0" data-row-index="11" id="style-Gm54t"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-NorEL" data-cell-id="B12" data-col-index="1" data-row-index="11" id="style-NorEL"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-NnOH7" data-cell-id="C12" data-col-index="2" data-row-index="11" id="style-NnOH7"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="elementor-section elementor-top-section elementor-element elementor-element-a2b1879 elementor-section-boxed elementor-section-height-default snipcss-wHaJZ" data-id="a2b1879" data-element_type="section">
    <div className="elementor-container elementor-column">
        <div className="elementor-row">
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0f37ddf" data-id="0f37ddf" data-element_type="column">
                <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                        <div className="elementor-element elementor-element-462633d elementor-widget elementor-widget-shortcode" data-id="462633d" data-element_type="widget" data-widget_type="shortcode.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-shortcode">
                                    <h3 className="wpdt-c h3xxx" id="wdt-table-title-10">{translations.contittit8}</h3>
                                    <div className="wpdt-c row wpDataTableContainerSimpleTable wpDataTables wpDataTablesWrapper
">
                                        <table id="wpdtSimpleTable-10" className="wpdtSimpleTable wpDataTable style-SdprE" data-column="3" data-rows="8" data-wpid="10" data-responsive="0" data-has-header="0">
                                            <tbody>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-tc-000000 wpdt-bc-D1D1D1 style-t1KAT" data-cell-id="A1" data-col-index="0" data-row-index="0" id="style-t1KAT"> {translations.contaddi}</td>
                                                    <td className="wpdt-cell wpdt-bc-D1D1D1 wpdt-tc-DBDBDB wpdt-empty-cell  style-49oYL" data-cell-id="B1" data-col-index="1" data-row-index="0" id="style-49oYL"></td>
                                                    <td className="wpdt-cell wpdt-bc-D1D1D1 wpdt-tc-DBDBDB wpdt-empty-cell  style-i4fzC" data-cell-id="C1" data-col-index="2" data-row-index="0" id="style-i4fzC"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-gJoFE" data-cell-id="A2" data-col-index="0" data-row-index="1" id="style-gJoFE"> {translations.contit3}</td>
                                                    <td className="wpdt-cell  style-orgnG" data-cell-id="B2" data-col-index="1" data-row-index="1" id="style-orgnG"> 11,986 mm</td>
                                                    <td className="wpdt-cell  style-V9vQ6" data-cell-id="C2" data-col-index="2" data-row-index="1" id="style-V9vQ6"> 39 ft 3.89 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-KBBpo" data-cell-id="A3" data-col-index="0" data-row-index="2" id="style-KBBpo"> {translations.contit4}</td>
                                                    <td className="wpdt-cell  style-SO77Z" data-cell-id="B3" data-col-index="1" data-row-index="2" id="style-SO77Z"> 2,236 mm</td>
                                                    <td className="wpdt-cell  style-lee2d" data-cell-id="C3" data-col-index="2" data-row-index="2" id="style-lee2d"> 7 ft 4.03 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-VCtFk" data-cell-id="A4" data-col-index="0" data-row-index="3" id="style-VCtFk"> {translations.contit5}</td>
                                                    <td className="wpdt-cell  style-lQTwn" data-cell-id="B4" data-col-index="1" data-row-index="3" id="style-lQTwn"> 1,968 mm</td>
                                                    <td className="wpdt-cell  style-g5FM6" data-cell-id="C4" data-col-index="2" data-row-index="3" id="style-g5FM6"> 6 ft 5.48 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-Tot1e" data-cell-id="A5" data-col-index="0" data-row-index="4" id="style-Tot1e"> {translations.contit8}</td>
                                                    <td className="wpdt-cell  style-Aiprf" data-cell-id="B5" data-col-index="1" data-row-index="4" id="style-Aiprf"> 45,000 kg</td>
                                                    <td className="wpdt-cell  style-KkINK" data-cell-id="C5" data-col-index="2" data-row-index="4" id="style-KkINK"> 13,180 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-K8DDf" data-cell-id="A6" data-col-index="0" data-row-index="5" id="style-K8DDf"> {translations.contit9}</td>
                                                    <td className="wpdt-cell  style-HIrkW" data-cell-id="B6" data-col-index="1" data-row-index="5" id="style-HIrkW"> 5,980 kg</td>
                                                    <td className="wpdt-cell  style-VpHtN" data-cell-id="C6" data-col-index="2" data-row-index="5" id="style-VpHtN"> 7 ft 5.45 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-ZskcF" data-cell-id="A7" data-col-index="0" data-row-index="6" id="style-ZskcF"> {translations.contit10}</td>
                                                    <td className="wpdt-cell  style-CbV6x" data-cell-id="B7" data-col-index="1" data-row-index="6" id="style-CbV6x"> 39,020 kg</td>
                                                    <td className="wpdt-cell  style-JA8lK" data-cell-id="C7" data-col-index="2" data-row-index="6" id="style-JA8lK"> 86,030 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-gWG84" data-cell-id="A8" data-col-index="0" data-row-index="7" id="style-gWG84"> {translations.contit11}</td>
                                                    <td className="wpdt-cell  style-6SsoZ" data-cell-id="B8" data-col-index="1" data-row-index="7" id="style-6SsoZ"> 53 {translations.contit12}</td>
                                                    <td className="wpdt-cell  style-7dtPN" data-cell-id="C8" data-col-index="2" data-row-index="7" id="style-7dtPN"> 1,862 feet {translations.contit13}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-bc90a9e" data-id="bc90a9e" data-element_type="column">
                <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                        <div className="elementor-element elementor-element-d6bbfc5 elementor-widget elementor-widget-shortcode" data-id="d6bbfc5" data-element_type="widget" data-widget_type="shortcode.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-shortcode">
                                    <h3 className="wpdt-c" id="wdt-table-title-9">{translations.contittit7}</h3>
                                    <div className="wpdt-c row wpDataTableContainerSimpleTable wpDataTables wpDataTablesWrapper
">
                                        <table id="wpdtSimpleTable-9" className="wpdtSimpleTable wpDataTable style-E2SNy" data-column="3" data-rows="12" data-wpid="9" data-responsive="0" data-has-header="0">
                                            <tbody>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-ZIG9D" data-cell-id="A1" data-col-index="0" data-row-index="0" id="style-ZIG9D"> {translations.contit3}</td>
                                                    <td className="wpdt-cell  style-LNPR3" data-cell-id="B1" data-col-index="1" data-row-index="0" id="style-LNPR3"> 11,587 mm</td>
                                                    <td className="wpdt-cell  style-Ll7vK" data-cell-id="C1" data-col-index="2" data-row-index="0" id="style-Ll7vK"> 38 ft 0.29 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell  style-torlp" data-cell-id="A2" data-col-index="0" data-row-index="1" id="style-torlp"> {translations.contit4}</td>
                                                    <td className="wpdt-cell  style-FI4kS" data-cell-id="B2" data-col-index="1" data-row-index="1" id="style-FI4kS"> 2,290 mm</td>
                                                    <td className="wpdt-cell  style-gtVhG" data-cell-id="C2" data-col-index="2" data-row-index="1" id="style-gtVhG"> 7 ft 6.16 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-fvy3h" data-cell-id="A3" data-col-index="0" data-row-index="2" id="style-fvy3h"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-zrRaA" data-cell-id="B3" data-col-index="1" data-row-index="2" id="style-zrRaA"> 2,539 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-aievn" data-cell-id="C3" data-col-index="2" data-row-index="2" id="style-aievn"> 8 ft 4.00 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC style-WJaL4" data-cell-id="A4" data-col-index="0" data-row-index="3" id="style-WJaL4"> {translations.contit6}</td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-DnI8l" data-cell-id="B4" data-col-index="1" data-row-index="3" id="style-DnI8l"></td>
                                                    <td className="wpdt-cell wpdt-bc-CCCCCC wpdt-empty-cell  style-VtqNk" data-cell-id="C4" data-col-index="2" data-row-index="3" id="style-VtqNk"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-4QWob" data-cell-id="A5" data-col-index="0" data-row-index="4" id="style-4QWob"> {translations.contit4}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-IZQgm" data-cell-id="B5" data-col-index="1" data-row-index="4" id="style-IZQgm"> 2,290 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-ftDA1" data-cell-id="C5" data-col-index="2" data-row-index="4" id="style-ftDA1"> 7 ft 6.16 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-N8vS5" data-cell-id="A6" data-col-index="0" data-row-index="5" id="style-N8vS5"> {translations.contit5}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-Ij7sf" data-cell-id="B6" data-col-index="1" data-row-index="5" id="style-Ij7sf"> 2,572 mm</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-bSEkd" data-cell-id="C6" data-col-index="2" data-row-index="5" id="style-bSEkd"> 8 ft 5.40 in</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-CACACA style-U3gic" data-cell-id="A7" data-col-index="0" data-row-index="6" id="style-U3gic"> {translations.contit7}</td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-cr4Dv" data-cell-id="B7" data-col-index="1" data-row-index="6" id="style-cr4Dv"></td>
                                                    <td className="wpdt-cell wpdt-bc-CACACA wpdt-empty-cell  style-Bgcii" data-cell-id="C7" data-col-index="2" data-row-index="6" id="style-Bgcii"></td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-zPMAK" data-cell-id="A8" data-col-index="0" data-row-index="7" id="style-zPMAK"> {translations.contit8}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-FCqdS" data-cell-id="B8" data-col-index="1" data-row-index="7" id="style-FCqdS"> 34,000 kg (30,480 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-vJnHG" data-cell-id="C8" data-col-index="2" data-row-index="7" id="style-vJnHG"> 74,960 lb (67,200 lb</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-xfQ5B" data-cell-id="A9" data-col-index="0" data-row-index="8" id="style-xfQ5B"> {translations.contit9}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-lXt6q" data-cell-id="B9" data-col-index="1" data-row-index="8" id="style-lXt6q"> 4,700 kg (4,800 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-oyX8R" data-cell-id="C9" data-col-index="2" data-row-index="8" id="style-oyX8R"> 10,360 lb (10,580 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-EePrQ" data-cell-id="A10" data-col-index="0" data-row-index="9" id="style-EePrQ"> {translations.contit10}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-9ZeoU" data-cell-id="B10" data-col-index="1" data-row-index="9" id="style-9ZeoU"> 29,300 kg (25,680 kg)</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-zpTqt" data-cell-id="C10" data-col-index="2" data-row-index="9" id="style-zpTqt"> 74,960 lb (56,620 lb)</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-BFNWp" data-cell-id="A11" data-col-index="0" data-row-index="10" id="style-BFNWp"> {translations.contit11}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-AZHCa" data-cell-id="B11" data-col-index="1" data-row-index="10" id="style-AZHCa"> 67.0 {translations.contit12}</td>
                                                    <td className="wpdt-cell wpdt-bc-FFFFFF style-X8lhT" data-cell-id="C11" data-col-index="2" data-row-index="10" id="style-X8lhT"> 2,378 {translations.contit13}</td>
                                                </tr>
                                                <tr className="wpdt-cell-row ">
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-NSmNd" data-cell-id="A12" data-col-index="0" data-row-index="11" id="style-NSmNd"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-A4lt7" data-cell-id="B12" data-col-index="1" data-row-index="11" id="style-A4lt7"></td>
                                                    <td className="wpdt-cell wpdt-bc-D6D6D6 wpdt-empty-cell  style-5QevP" data-cell-id="C12" data-col-index="2" data-row-index="11" id="style-5QevP"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  <FooterFour></FooterFour>

      </div>

        </>
    )
}

export default Continerr;
