import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import ReactPlayer from "react-player";
import { FiPlay, FiZoomIn, FiFolderPlus, FiSearch } from "react-icons/fi";

function GalleryOne({ galleryItem }) {
  const { image, popupLink } = galleryItem;

  const getFileType = (url) => {
    const fileExtension = url.split('.').pop().toLowerCase();
    return fileExtension;
  };

  const iconHandle = () => {
    const fileType = getFileType(popupLink);

    if (popupLink) {
      if (popupLink.length > 1) {
        if (fileType === "youtube") {
          return <FiFolderPlus />;
        } else if (["mp4", "avi", "mkv", "mov"].includes(fileType)) {
          return <FiPlay />;
        }
        return <FiSearch />;
      } else if (["mp4", "avi", "mkv", "mov", "youtube"].includes(fileType)) {
        return <FiPlay />;
      } else {
        return <FiZoomIn />;
      }
    } else {
      return <FiZoomIn />;
    }
  };

  const [toggler, setToggler] = useState(false);

  return (
    <div className="rn-gallery icon-center">
      <div className="thumbnail">
        <img
          className="radius-small innercrack"
          src={image}
          alt="Corporate Image"
          style={{ borderRadius: "0px" }}
        />
      </div>
      {popupLink && (
        <div className="video-icon">
          <button
            className="btn-default rounded-player sm-size"
            onClick={() => setToggler(!toggler)}
          >
            <span>{iconHandle()}</span>
          </button>
        </div>
      )}
      <FsLightbox toggler={toggler} sources={popupLink && [popupLink]} />
      {popupLink && (
        <ReactPlayer
          url={popupLink}
          controls
          playing={toggler}
          style={{ display: "none" }}
        />
      )}
    </div>
  );
}

export default GalleryOne;
