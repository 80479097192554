import {
  BufferAttribute, BufferGeometry, Color, DoubleSide, Group, Material, Mesh, MeshBasicMaterial, NormalBlending,
  Object3D, Points, PointsMaterial, ShaderMaterial, SphereGeometry, Sprite, SpriteMaterial, Texture, TextureLoader, Vector3
} from "three";

import html2canvas from "html2canvas";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { createAnimateLine, createLightPillar, createPointMesh, createWaveMesh, getCirclePoints, lon2xyz } from "../Utils/common";
import gsap from "gsap";
import { flyArc } from "../Utils/arc";
import * as THREE from 'three';

export type punctuation = {
  circleColor: number,
  lightColumn: {
    startColor: number,
    endColor: number,
  },
}

type options = {
  data: {
    startArray: {
      name: string,
      E: number,
      N: number,
    },
    endArray: {
      name: string,
      E: number,
      N: number,
    }[]
  }[],
  dom: HTMLElement,
  textures: Record<string, Texture>,
  earth: {
    radius: number,
    rotateSpeed: number,
    isRotation: boolean,
  },
  satellite: {
    show: boolean,
    rotateSpeed: number,
    size: number,
    number: number,
  },
  punctuation: punctuation,
  flyLine: {
    color: number,
    speed: number,
    flyLineColor: number,
  },
}

type uniforms = {
  glowColor: { value: Color; },
  scale: { type: string; value: number; },
  bias: { type: string; value: number; },
  power: { type: string; value: number; },
  time: { type: string; value: any; },
  isHover: { value: boolean; },
  map: { value: Texture },
}

const earthVertex = `
  varying vec2 vUv;
  varying vec3 vNormal;
  varying vec3 vp;
  varying vec3 vPositionNormal;

  void main(void) {
    vUv = uv;
    vNormal = normalize(normalMatrix * normal); // 转换到视图空间
    vp = position;
    vPositionNormal = normalize((modelViewMatrix * vec4(position, 1.0)).xyz);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const earthFragment = `
  uniform vec3 glowColor;
  uniform float bias;
  uniform float power;
  uniform float time;
  uniform float scale;
  uniform sampler2D map;

  varying vec3 vp;
  varying vec3 vNormal;
  varying vec3 vPositionNormal;
  varying vec2 vUv;

  void main(void) {
    vec4 baseColor = texture2D(map, vUv);
    float a = pow(bias + scale * abs(dot(vNormal, vPositionNormal)), power);
    if (vp.y > time && vp.y < time + 20.0) {
      float t = smoothstep(0.0, 0.8, (1.0 - abs(0.5 - (vp.y - time) / 20.0)) / 3.0);
      baseColor = mix(baseColor, vec4(glowColor, 1.0), t * t);
    }
    gl_FragColor = mix(baseColor, vec4(glowColor, 1.0), a);
  }
`;

export default class Earth {
  public group: Group;
  public earthGroup: Group;
  public around: BufferGeometry;
  public aroundPoints: Points<BufferGeometry, PointsMaterial>;
  public options: options;
  public uniforms: uniforms;
  public timeValue: number;
  public earth: Mesh<SphereGeometry, ShaderMaterial>;
  public punctuationMaterial: MeshBasicMaterial;
  public markupPoint: Group;
  public waveMeshArr: Object3D[];
  public circleLineList: any[];
  public circleList: any[];
  public x: number;
  public n: number;
  public isRotation: boolean;
  public flyLineArcGroup: Group;

  constructor(options: options) {
    this.options = options;
    this.group = new Group();

    this.group.name = "group";
    this.group.scale.set(0, 0, 0);
    this.earthGroup = new Group();

    this.group.add(this.earthGroup);
    this.earthGroup.name = "EarthGroup";
    this.markupPoint = new Group();
    this.markupPoint.name = "markupPoint";
    this.waveMeshArr = [];
    this.circleLineList = [];
    this.circleList = [];
    this.x = 0;
    this.n = 0;
    this.isRotation = this.options.earth.isRotation;
    this.timeValue = 100;
    this.uniforms = {
      glowColor: { value: new Color(0x0cd1eb) },
      scale: { type: "f", value: -1.0 },
      bias: { type: "f", value: 1.0 },
      power: { type: "f", value: 3.3 },
      time: { type: "f", value: this.timeValue },
      isHover: { value: false },
      map: { value: null },
    };
  }

  async init(): Promise<void> {
    const startTime = performance.now();
    console.log('Initialization started at:', startTime);
    return new Promise(async (resolve) => {
      await Promise.all([
        this.createEarth(), // Dünya'yı oluşturma
        this.createStars(), // Yıldızları ekleme
        this.createEarthGlow(), // Dünya'nın parıltısını oluşturma
        this.createEarthAperture(), // Dünya'nın atmosferini oluşturma
        this.createMarkupPoint(), // İşaret noktalarını oluşturma
        this.createFlyLine() // Uçuş çizgisini oluşturma
      ]);
      this.show();
      const endTime = performance.now();
      console.log('Initialization finished at:', endTime, 'Total Duration:', endTime - startTime);
      resolve();
    });
  }

  createEarth() {
    const earth_geometry = new SphereGeometry(
      this.options.earth.radius,
      50,
      50
    );

    const earth_border = new SphereGeometry(
      this.options.earth.radius + 10,
      60,
      60
    );

    const pointMaterial = new PointsMaterial({
      color: 0x81ffff,
      transparent: true,
      sizeAttenuation: true,
      opacity: 0.1,
      vertexColors: false,
      size: 0.01,
    });
    const points = new Points(earth_border, pointMaterial);
    this.earthGroup.add(points);

    this.uniforms.map.value = this.options.textures.earth;

    const earth_material = new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: earthVertex,
      fragmentShader: earthFragment,
    });

    earth_material.needsUpdate = true;
    this.earth = new Mesh(earth_geometry, earth_material);
    this.earth.name = "earth";

    this.earthGroup.add(this.earth);

  }

  createStars() {
    const startTime = performance.now();
  console.log('createStars started at:', startTime);
    const vertices = [];
    const colors = [];
    for (let i = 0; i < 500; i++) {
      const vertex = new Vector3();
      vertex.x = 800 * Math.random() - 300;
      vertex.y = 800 * Math.random() - 300;
      vertex.z = 800 * Math.random() - 300;
      vertices.push(vertex.x, vertex.y, vertex.z);
      colors.push(new Color(1, 1, 1));
    }

    this.around = new BufferGeometry();
    this.around.setAttribute("position", new BufferAttribute(new Float32Array(vertices), 3));
    this.around.setAttribute("color", new BufferAttribute(new Float32Array(colors), 3));

    const aroundMaterial = new PointsMaterial({
      size: 2,
      sizeAttenuation: true,
      color: 0x4d76cf,
      transparent: true,
      opacity: 1,
      map: this.options.textures.gradient,
    });

    this.aroundPoints = new Points(this.around, aroundMaterial);
    this.aroundPoints.name = "星空";
    this.aroundPoints.scale.set(1, 1, 1);
    this.group.add(this.aroundPoints);
    const endTime = performance.now();
    console.log('createStars finished at:', endTime, 'Duration:', endTime - startTime);
  }

  createEarthGlow() {
    const startTime = performance.now();
    console.log('createEarthGlow started at:', startTime);
    const R = this.options.earth.radius;
    const texture = this.options.textures.glow;

    const spriteMaterial = new SpriteMaterial({
      map: texture,
      color: 0x4390d1,
      transparent: true,
      opacity: 0.7,
      depthWrite: false,
    });

    const sprite = new Sprite(spriteMaterial);
    sprite.scale.set(R * 3.0, R * 3.0, 1);
    this.earthGroup.add(sprite);
    const endTime = performance.now();
    console.log('createEarthGlow finished at:', endTime, 'Duration:', endTime - startTime);
  }

  createEarthAperture() {
    const vertexShader = [
      "varying vec3	vVertexWorldPosition;",
      "varying vec3	vVertexNormal;",
      "varying vec4	vFragColor;",
      "void main(){",
      "	vVertexNormal	= normalize(normalMatrix * normal);",
      "	vVertexWorldPosition	= (modelMatrix * vec4(position, 1.0)).xyz;",
      "	gl_Position	= projectionMatrix * modelViewMatrix * vec4(position, 1.0);",
      "}",
    ].join("\n");

    const AeroSphere = {
      uniforms: {
        coeficient: { type: "f", value: 1.0 },
        power: { type: "f", value: 3 },
        glowColor: { type: "c", value: new Color(0x4390d1) },
      },
      vertexShader: vertexShader,
      fragmentShader: [
        "uniform vec3	glowColor;",
        "uniform float	coeficient;",
        "uniform float	power;",
        "varying vec3	vVertexNormal;",
        "varying vec3	vVertexWorldPosition;",
        "varying vec4	vFragColor;",
        "void main(){",
        "	vec3 worldCameraToVertex = vVertexWorldPosition - cameraPosition;",
        "	vec3 viewCameraToVertex	= (viewMatrix * vec4(worldCameraToVertex, 0.0)).xyz;",
        "	viewCameraToVertex= normalize(viewCameraToVertex);",
        "	float intensity	= pow(coeficient + dot(vVertexNormal, viewCameraToVertex), power);",
        "	gl_FragColor = vec4(glowColor, intensity);",
        "}",
      ].join("\n"),
    };

    const material1 = new ShaderMaterial({
      uniforms: AeroSphere.uniforms,
      vertexShader: AeroSphere.vertexShader,
      fragmentShader: AeroSphere.fragmentShader,
      blending: NormalBlending,
      transparent: true,
      depthWrite: false,
    });

    const sphere = new SphereGeometry(this.options.earth.radius, 50, 50);
    const mesh = new Mesh(sphere, material1);
    this.earthGroup.add(mesh);
  }

  async createMarkupPoint() {
    const startTime = performance.now();
  console.log('createMarkupPoint started at:', startTime);
    await Promise.all(this.options.data.map(async (item) => {
      const radius = this.options.earth.radius;
      const lon = item.startArray.E;
      const lat = item.startArray.N;

      this.punctuationMaterial = new MeshBasicMaterial({
        color: this.options.punctuation.circleColor,
        map: this.options.textures.label,
        transparent: true,
        depthWrite: false,
      });

      const mesh = createPointMesh({ radius, lon, lat, material: this.punctuationMaterial });
      this.markupPoint.add(mesh);

      const LightPillar = createLightPillar({
        radius: this.options.earth.radius,
        lon,
        lat,
        index: 0,
        textures: this.options.textures,
        punctuation: this.options.punctuation,
      });
      this.markupPoint.add(LightPillar);

      const WaveMesh = createWaveMesh({ radius, lon, lat, textures: this.options.textures });
      this.markupPoint.add(WaveMesh);
      this.waveMeshArr.push(WaveMesh);

      await Promise.all(item.endArray.map(async (obj) => {
        const lon = obj.E;
        const lat = obj.N;
        const mesh = createPointMesh({ radius, lon, lat, material: this.punctuationMaterial });
        this.markupPoint.add(mesh);

        const LightPillar = createLightPillar({
          radius: this.options.earth.radius,
          lon,
          lat,
          index: 1,
          textures: this.options.textures,
          punctuation: this.options.punctuation,
        });
        this.markupPoint.add(LightPillar);

        const WaveMesh = createWaveMesh({ radius, lon, lat, textures: this.options.textures });
        this.markupPoint.add(WaveMesh);
        this.waveMeshArr.push(WaveMesh);
      }));
      this.earthGroup.add(this.markupPoint);
    }));
    const endTime = performance.now();
    console.log('createMarkupPoint finished at:', endTime, 'Duration:', endTime - startTime);
  }

  async createSpriteLabel() {
    const shareContent = document.getElementById("html2canvas");
    await Promise.all(this.options.data.map(async (item) => {
      let cityArry = [];
      cityArry.push(item.startArray);
      cityArry = cityArry.concat(...item.endArray);

      await Promise.all(cityArry.map(async (e) => {
        const p = lon2xyz(this.options.earth.radius * 1.001, e.E, e.N);
        const div = `<div class="fire-div">${e.name}</div>`;
        shareContent.innerHTML = div;

        const opts = {
          backgroundColor: null,
          scale: 6,
          dpi: window.devicePixelRatio,
        };
        const canvas = await html2canvas(shareContent, opts);
        const dataURL = canvas.toDataURL("image/png");
        const map = new TextureLoader().load(dataURL);

        const material = new SpriteMaterial({
          map: map,
          transparent: true,
        });

        const sprite = new Sprite(material);
        const len = 5 + (e.name.length - 2) * 2;
        sprite.scale.set(len, 3, 1);
        sprite.position.set(p.x * 1.1, p.y * 1.1, p.z * 1.1);
        this.earth.add(sprite);
      }));
    }));
  }

  createAnimateCircle() {

    const list = getCirclePoints({
      radius: this.options.earth.radius + 15,
      number: 150,
      closed: true,
    });

    const mat = new MeshBasicMaterial({
      color: "#0c3172",
      transparent: true,
      opacity: 0.4,
      side: DoubleSide,
    });

    const line = createAnimateLine({
      pointList: list,
      material: mat,
      number: 100,
      radius: 0.1,
    });

    this.earthGroup.add(line);

    const l2 = line.clone();
    l2.scale.set(1.2, 1.2, 1.2);
    l2.rotateZ(Math.PI / 6);
    this.earthGroup.add(l2);

    const l3 = line.clone();
    l3.scale.set(0.8, 0.8, 0.8);
    l3.rotateZ(-Math.PI / 6);
    this.earthGroup.add(l3);

    const ball = new Mesh(
      new SphereGeometry(this.options.satellite.size, 32, 32),
      new MeshBasicMaterial({ color: "#e0b187" })
    );

    const ball2 = new Mesh(
      new SphereGeometry(this.options.satellite.size, 32, 32),
      new MeshBasicMaterial({ color: "#628fbb" })
    );

    const ball3 = new Mesh(
      new SphereGeometry(this.options.satellite.size, 32, 32),
      new MeshBasicMaterial({ color: "#806bdf" })
    );

    this.circleLineList.push(line, l2, l3);
    ball.name = ball2.name = ball3.name = "卫星";

    for (let i = 0; i < this.options.satellite.number; i++) {
      const ball01 = ball.clone();
      const num = Math.floor(list.length / this.options.satellite.number);
      ball01.position.set(
        list[num * (i + 1)][0],
        list[num * (i + 1)][1],
        list[num * (i + 1)][2]
      );
      line.add(ball01);

      const ball02 = ball2.clone();
      const num02 = Math.floor(list.length / this.options.satellite.number);
      ball02.position.set(
        list[num02 * (i + 1)][0],
        list[num02 * (i + 1)][1],
        list[num02 * (i + 1)][2]
      );
      l2.add(ball02);

      const ball03 = ball2.clone();
      const num03 = Math.floor(list.length / this.options.satellite.number);
      ball03.position.set(
        list[num03 * (i + 1)][0],
        list[num03 * (i + 1)][1],
        list[num03 * (i + 1)][2]
      );
      l3.add(ball03);
    }

  }

  createFlyLine() {
    const startTime = performance.now();
    console.log('createFlyLine started at:', startTime);
    this.flyLineArcGroup = new Group();
    this.flyLineArcGroup.userData['flyLineArray'] = [];
    this.earthGroup.add(this.flyLineArcGroup);

    this.options.data.forEach((cities) => {
      cities.endArray.forEach((item) => {
        const arcline = flyArc(
          this.options.earth.radius,
          cities.startArray.E,
          cities.startArray.N,
          item.E,
          item.N,
          this.options.flyLine
        );

        this.flyLineArcGroup.add(arcline);
        this.flyLineArcGroup.userData['flyLineArray'].push(arcline.userData['flyLine']);
      });
    });
    const endTime = performance.now();
    console.log('createFlyLine finished at:', endTime, 'Duration:', endTime - startTime);
  }

  show() {
    gsap.to(this.group.scale, {
      x: 1,
      y: 1,
      z: 1,
      duration: 2,
      ease: "Quadratic",
    });
  }

  render() {
    this.flyLineArcGroup?.userData['flyLineArray']?.forEach((fly) => {
      fly.rotation.z += this.options.flyLine.speed;
      if (fly.rotation.z >= fly.flyEndAngle) fly.rotation.z = 0;
    });

    if (this.isRotation) {
      this.earthGroup.rotation.y += this.options.earth.rotateSpeed;
    }

    this.circleLineList.forEach((e) => {
      e.rotateY(this.options.satellite.rotateSpeed);
    });

    this.uniforms.time.value =
      this.uniforms.time.value < -this.timeValue
        ? this.timeValue
        : this.uniforms.time.value - 1;

    if (this.waveMeshArr.length) {
      this.waveMeshArr.forEach((mesh: Mesh) => {
        mesh.userData['scale'] += 0.007;
        mesh.scale.set(
          mesh.userData['size'] * mesh.userData['scale'],
          mesh.userData['size'] * mesh.userData['scale'],
          mesh.userData['size'] * mesh.userData['scale']
        );
        if (mesh.userData['scale'] <= 1.5) {
          (mesh.material as Material).opacity = (mesh.userData['scale'] - 1) * 2;
        } else if (mesh.userData['scale'] > 1.5 && mesh.userData['scale'] <= 2) {
          (mesh.material as Material).opacity = 1 - (mesh.userData['scale'] - 1.5) * 2;
        } else {
          mesh.userData['scale'] = 1;
        }
      });
    }
  }
}
